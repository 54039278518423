import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withKeys as _withKeys, vModelText as _vModelText, unref as _unref, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "font-inter flex flex-col gap-8 companies query-company pt-12 sm:pt-6" }
const _hoisted_2 = { class: "grid grid-cols-12 query-company__filter gap-6" }
const _hoisted_3 = { class: "query-company__filter__search flex col-span-12" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = {
  key: 0,
  class: "flex flex-col items-center gap-4 justify-center"
}
const _hoisted_6 = {
  key: 1,
  class: "flex items-center justify-center flex-col gap-2"
}
const _hoisted_7 = {
  key: 0,
  class: "font-bold"
}
const _hoisted_8 = {
  key: 1,
  class: "flex flex-col"
}
const _hoisted_9 = { class: "p-8" }
const _hoisted_10 = { class: "flex items-center font-inter justify-between mb-4" }
const _hoisted_11 = { class: "flex flex-col gap-4 py-4" }
const _hoisted_12 = { class: "font-inter flex flex-col gap-2 col-span-6" }
const _hoisted_13 = { class: "font-inter flex justify-between pt-8 submit-wrapper" }
const _hoisted_14 = ["disabled"]

import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";
import { ref } from "vue";
import axiosClient from "@/api/axios";
import { vMaska } from "maska/vue"
import SpinnerLoader from '@/components/loader/SpinnerLoader.vue'
import MyModal from "@/components/modal/MyModal.vue";
import { toast } from 'vue3-toastify';


export default /*@__PURE__*/_defineComponent({
  __name: 'QueryCompanyView',
  setup(__props) {

const search = ref('')
const percentage = ref()
const isLoading = ref(false)
const notFound = ref(false)
const isCreateBlockingReasonModalOpen = ref(false)
const reportPayload = ref<Record<string, any>>({})
const isReportRequestLoading = ref(false)

function getCompany(){
    isLoading.value = true

    axiosClient.get(`/companies/${search.value.replace(/[-\/.]/g, '')}`)
    .then((res) => {
        percentage.value = res.data.score
        notFound.value = false
    }).catch(() => {
        notFound.value = true
    }).finally(() => isLoading.value = false)
}

function reportCompany(){
    isReportRequestLoading.value = true

    axiosClient.post('/companies/report', { reason: reportPayload.value.reason, document: search.value.replace(/[\-\.\//]/g, '') })
    .then(() => {
        reportPayload.value = {}
        isCreateBlockingReasonModalOpen.value = false
        toast.success('Denúncia cadastrada com sucesso')
    }).finally(() => isReportRequestLoading.value = false)
}

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[16] || (_cache[16] = _createElementVNode("div", null, [
      _createElementVNode("h1", { class: "text-3xl font-bold text-292 font-nunito" }, "Consultar empresa"),
      _createElementVNode("p", { class: "text-gray-500 text-sm" }, "Consulte pelo CPF ou CNPJ da empresa")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("form", {
          onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"])),
          class: "rounded-xl w-full relative"
        }, [
          _withDirectives(_createElementVNode("input", {
            type: "tel",
            placeholder: "Digite um CPF ou CNPJ",
            class: "rounded-tl-xl rounded-bl-xl input-primary w-full h-full p-4 py-3 bg-f5 pl-11 border",
            onKeydown: _withKeys(getCompany, ["enter"]),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((search).value = $event)),
            onBlur: _cache[1] || (_cache[1] = ($event: any) => (search.value.length !== 64 ? _unref(toast).error('Por favor, criptografe o número do documento utilizando a criptografia sha256') : ''))
          }, null, 544), [
            [_vModelText, search.value]
          ]),
          _cache[7] || (_cache[7] = _createElementVNode("svg", {
            class: "absolute top-1/2 -translate-y-1/2 left-4",
            xmlns: "http://www.w3.org/2000/svg",
            width: "16",
            height: "16",
            viewBox: "0 0 16 16",
            fill: "none"
          }, [
            _createElementVNode("path", {
              d: "M0.0247006 7.87823C0.162935 9.46532 0.887052 10.9532 2.06688 12.0735C3.24671 13.1938 4.80475 13.8732 6.46033 13.9891C8.11592 14.1051 9.76012 13.6501 11.0972 12.7061L14.2375 15.7092C14.4312 15.8952 14.6938 15.9996 14.9677 15.9996C15.2415 15.9996 15.5043 15.8953 15.6978 15.7093C15.8915 15.5235 16.0002 15.2714 16 15.0085C16.0002 14.7458 15.8915 14.4937 15.698 14.3078L12.5681 11.2947C13.9283 9.5308 14.2998 7.23853 13.5616 5.1613C12.8236 3.08439 11.0702 1.48725 8.86994 0.888013C6.66989 0.288729 4.30337 0.763496 2.53789 2.15874C0.772543 3.55382 -0.16685 5.69131 0.0244658 7.87791L0.0247006 7.87823ZM6.96867 2.62946C8.26895 2.62946 9.51591 3.12511 10.4353 4.00726C11.3547 4.88941 11.8713 6.08607 11.8713 7.33363C11.8713 8.58119 11.3547 9.77768 10.4353 10.6598C9.51591 11.542 8.2689 12.0376 6.96867 12.0376C5.66844 12.0376 4.42124 11.542 3.50184 10.6598C2.58245 9.77768 2.06587 8.58119 2.06587 7.33363C2.06587 6.08607 2.58245 4.88941 3.50184 4.00726C4.42124 3.12511 5.66844 2.62946 6.96867 2.62946Z",
              fill: "#676767"
            })
          ], -1))
        ], 32),
        _createElementVNode("button", {
          onClick: getCompany,
          class: "rounded-tr-lg rounded-br-lg font-inter text-white font-bold",
          disabled: search.value.length !== 64
        }, " pesquisar ", 8, _hoisted_4)
      ])
    ]),
    _createElementVNode("section", null, [
      (percentage.value !== undefined && !notFound.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_unref(CircleProgress), {
              percent: percentage.value,
              showPercent: true
            }, null, 8, ["percent"]),
            _cache[8] || (_cache[8] = _createStaticVNode("<p class=\"text-orange-500 flex items-center gap-2\" data-v-1e78408a><svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" data-v-1e78408a><path class=\"stroke-orange-500\" d=\"M12 7.75V13\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-1e78408a></path><path class=\"stroke-orange-500\" d=\"M21.08 8.58003V15.42C21.08 16.54 20.4799 17.58 19.5099 18.15L13.5699 21.58C12.5999 22.14 11.3999 22.14 10.4199 21.58L4.47992 18.15C3.50992 17.59 2.90991 16.55 2.90991 15.42V8.58003C2.90991 7.46003 3.50992 6.41999 4.47992 5.84999L10.4199 2.42C11.3899 1.86 12.5899 1.86 13.5699 2.42L19.5099 5.84999C20.4799 6.41999 21.08 7.45003 21.08 8.58003Z\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-1e78408a></path><path d=\"M12 16.2V16.2999\" class=\"stroke-orange-500\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-1e78408a></path></svg> Cadastro com risco de aprovação </p>", 1)),
            _createElementVNode("button", {
              onClick: _cache[3] || (_cache[3] = ($event: any) => (isCreateBlockingReasonModalOpen.value = true)),
              class: "border border-emerald-600 border-2 rounded-3xl px-4 py-2 font-bold text-emerald-600 flex items-center gap-2 add mx-auto"
            }, " Adicionar Motivo de Recusa ")
          ]))
        : _createCommentVNode("", true),
      ((percentage.value == undefined && !isLoading.value) || (notFound.value && !isLoading.value))
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _cache[11] || (_cache[11] = _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              width: "104",
              height: "104",
              viewBox: "0 0 24 24",
              fill: "none"
            }, [
              _createElementVNode("path", {
                d: "M20.5902 10.5501V7.12006C20.5902 5.89006 19.6502 4.53006 18.5002 4.10006L13.5102 2.23006C12.6802 1.92006 11.3202 1.92006 10.4902 2.23006L5.50016 4.11006C4.35016 4.54006 3.41016 5.90006 3.41016 7.12006V14.5501C3.41016 15.7301 4.19016 17.2801 5.14016 17.9901L9.44016 21.2001C10.1402 21.7401 11.0702 22.0001 12.0002 22.0001",
                stroke: "#171717",
                "stroke-width": "1.5",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }),
              _createElementVNode("path", {
                d: "M16 20C18.2091 20 20 18.2091 20 16C20 13.7909 18.2091 12 16 12C13.7909 12 12 13.7909 12 16C12 18.2091 13.7909 20 16 20Z",
                stroke: "#171717",
                "stroke-width": "1.5",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }),
              _createElementVNode("path", {
                d: "M20.9955 21H21.0045",
                stroke: "#171717",
                "stroke-width": "1.5",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              })
            ], -1)),
            (!notFound.value)
              ? (_openBlock(), _createElementBlock("p", _hoisted_7, "Digite e aperte enter para fazer uma busca"))
              : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _cache[10] || (_cache[10] = _createElementVNode("p", { class: "text-yellow-500 pb-2" }, "Esta empresa não está cadastrada", -1)),
                  _createVNode(_component_RouterLink, {
                    to: { name: 'companies.create', query: { document: search.value } },
                    class: "border border-emerald-600 border-2 rounded-3xl px-4 py-2 font-bold text-emerald-600 flex items-center gap-2 add mx-auto"
                  }, {
                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createElementVNode("svg", {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "24",
                        height: "24",
                        viewBox: "0 0 24 24",
                        fill: "none"
                      }, [
                        _createElementVNode("path", {
                          d: "M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z",
                          stroke: "#171717",
                          class: "stroke-emerald-600",
                          "stroke-width": "1.5",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round"
                        }),
                        _createElementVNode("path", {
                          d: "M8 12H16",
                          stroke: "#171717",
                          "stroke-width": "1.5",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                          class: "stroke-emerald-600"
                        }),
                        _createElementVNode("path", {
                          d: "M12 16V8",
                          stroke: "#171717",
                          "stroke-width": "1.5",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                          class: "stroke-emerald-600"
                        })
                      ], -1),
                      _createTextVNode(" Adicionar ")
                    ])),
                    _: 1
                  }, 8, ["to"])
                ]))
          ]))
        : _createCommentVNode("", true),
      (isLoading.value)
        ? (_openBlock(), _createBlock(SpinnerLoader, { key: 2 }))
        : _createCommentVNode("", true)
    ]),
    _createVNode(MyModal, {
      isOpen: isCreateBlockingReasonModalOpen.value,
      toggleModal: (v) => isCreateBlockingReasonModalOpen.value = v,
      width: '40rem',
      height: '37rem',
      id: 'create-blocking-reason-modal'
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _cache[13] || (_cache[13] = _createElementVNode("h2", { class: "text-2xl font-bold" }, "Adicione um motivo de bloqueio", -1)),
            _createElementVNode("button", {
              onClick: _cache[4] || (_cache[4] = ($event: any) => (isCreateBlockingReasonModalOpen.value = false))
            }, _cache[12] || (_cache[12] = [
              _createElementVNode("svg", {
                xmlns: "http://www.w3.org/2000/svg",
                width: "24",
                height: "24",
                viewBox: "0 0 24 24",
                fill: "none"
              }, [
                _createElementVNode("path", {
                  d: "M18 6L6 18",
                  stroke: "#090909",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round"
                }),
                _createElementVNode("path", {
                  d: "M6 6L18 18",
                  stroke: "#090909",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round"
                })
              ], -1)
            ]))
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _cache[14] || (_cache[14] = _createElementVNode("label", { class: "font-bold text-lg" }, "Motivo", -1)),
              _cache[15] || (_cache[15] = _createElementVNode("p", { class: "text-zinc-500" }, "Adicione o motivo pelo qual você está denunciando essa empresa", -1)),
              _withDirectives(_createElementVNode("textarea", {
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((reportPayload.value.reason) = $event)),
                type: "text",
                placeholder: "ex: Esta empresa não passou no compliance"
              }, null, 512), [
                [_vModelText, reportPayload.value.reason]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            (!isReportRequestLoading.value)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "bg-emerald-600 font-bold text-white px-12 py-3 rounded-3xl",
                  onClick: reportCompany,
                  disabled: !reportPayload.value.reason
                }, "Enviar", 8, _hoisted_14))
              : (_openBlock(), _createBlock(SpinnerLoader, { key: 1 })),
            _createElementVNode("button", {
              onClick: _cache[6] || (_cache[6] = ($event: any) => (isCreateBlockingReasonModalOpen.value = false))
            }, "Cancelar")
          ])
        ])
      ]),
      _: 1
    }, 8, ["isOpen", "toggleModal"])
  ]))
}
}

})