import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  id: "profile",
  class: "pt-12 sm:pt-6"
}
const _hoisted_2 = { class: "register" }
const _hoisted_3 = ["value"]
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "password" }
const _hoisted_6 = ["type"]
const _hoisted_7 = {
  key: 0,
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_8 = {
  key: 1,
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none"
}
const _hoisted_9 = ["type"]
const _hoisted_10 = {
  key: 0,
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_11 = {
  key: 1,
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none"
}
const _hoisted_12 = ["type"]
const _hoisted_13 = {
  key: 0,
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_14 = {
  key: 1,
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none"
}
const _hoisted_15 = ["disabled"]

import { ref, watch, onMounted } from 'vue'
import axiosClient from '@/api/axios'
import useCookie from '@/composables/useCookie'
import { toast } from "vue3-toastify";
import SpinnerLoaderButton from "@/components/loader/SpinnerLoaderButton.vue";



export default /*@__PURE__*/_defineComponent({
  __name: 'ProfileView',
  setup(__props) {

const { getCookie } = useCookie()
const user = ref<any>(getCookie('authenticated_user') ? JSON.parse(getCookie('authenticated_user') as string) : { address: {} })
const isUpdating = ref(false)

const profilePayload = ref({
    oldPassword: '',
    newPassword: '',
    newPasswordConfirmation: ''
  })

watch(() => user.value, () => {
  profilePayload.value = ({
    oldPassword: '',
    newPassword: '',
    newPasswordConfirmation: ''
  })
})

function updateProfile () {
  isUpdating.value = true

  axiosClient.post('/accounts/password/change', profilePayload.value).then((res) => {
    toast.success('Senha atualizada com sucesso')
  })
  .catch((error) => {
    toast.error('Senha atual incorreta')
  })
  .finally(() => isUpdating.value = false)
}

const showCurrentPassword = ref<boolean>(false)
const showNewPassword = ref<boolean>(false)
const showRepeatNewPassword = ref<boolean>(false)

onMounted(() => {

})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'companies.query' }))),
      class: "back"
    }, _cache[7] || (_cache[7] = [
      _createElementVNode("figure", null, [
        _createElementVNode("svg", {
          xmlns: "http://www.w3.org/2000/svg",
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none"
        }, [
          _createElementVNode("path", {
            d: "M9.57 5.93018L3.5 12.0002L9.57 18.0702M20.5 12.0002H3.67",
            "stroke-width": "1.5",
            "stroke-miterlimit": "10",
            "stroke-linecap": "round",
            "stroke-linejoin": "round"
          })
        ])
      ], -1),
      _createElementVNode("p", null, "Perfil", -1)
    ])),
    _cache[20] || (_cache[20] = _createElementVNode("input", {
      autocomplete: "off",
      type: "radio",
      name: "profile",
      id: "profile-register",
      checked: ""
    }, null, -1)),
    _cache[21] || (_cache[21] = _createElementVNode("input", {
      autocomplete: "off",
      type: "radio",
      name: "profile",
      id: "profile-password"
    }, null, -1)),
    _createElementVNode("main", null, [
      _cache[19] || (_cache[19] = _createElementVNode("div", { class: "tab" }, [
        _createElementVNode("label", { for: "profile-register" }, "Cadastro"),
        _createElementVNode("label", { for: "profile-password" }, "Senha")
      ], -1)),
      _createElementVNode("form", _hoisted_2, [
        _createElementVNode("section", null, [
          _cache[8] || (_cache[8] = _createElementVNode("label", { for: "name" }, [
            _createElementVNode("strong", null, "Nome"),
            _createTextVNode(" (obrigatório)")
          ], -1)),
          _createElementVNode("input", {
            autocomplete: "off",
            type: "text",
            value: user.value.name,
            name: "name",
            id: "name",
            disabled: ""
          }, null, 8, _hoisted_3)
        ]),
        _createElementVNode("section", null, [
          _cache[9] || (_cache[9] = _createElementVNode("label", { for: "email" }, [
            _createElementVNode("strong", null, "E-mail"),
            _createTextVNode(" (obrigatório)")
          ], -1)),
          _createElementVNode("input", {
            autocomplete: "off",
            value: user.value.email,
            type: "text",
            name: "email",
            id: "email",
            disabled: ""
          }, null, 8, _hoisted_4)
        ])
      ]),
      _createElementVNode("form", _hoisted_5, [
        _createElementVNode("section", null, [
          _cache[12] || (_cache[12] = _createElementVNode("label", { for: "new-password" }, [
            _createElementVNode("strong", null, "Senha atual"),
            _createTextVNode(" (obrigatório)")
          ], -1)),
          _withDirectives(_createElementVNode("input", {
            autocomplete: "off",
            type: showCurrentPassword.value ? 'text':'password',
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((profilePayload.value.oldPassword) = $event)),
            name: "new-password",
            id: "new-password"
          }, null, 8, _hoisted_6), [
            [_vModelDynamic, profilePayload.value.oldPassword]
          ]),
          _createElementVNode("button", {
            type: "button",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (showCurrentPassword.value = !showCurrentPassword.value))
          }, [
            (showCurrentPassword.value)
              ? (_openBlock(), _createElementBlock("svg", _hoisted_7, _cache[10] || (_cache[10] = [
                  _createElementVNode("g", { id: "vuesax/linear/eye" }, [
                    _createElementVNode("g", { id: "eye" }, [
                      _createElementVNode("path", {
                        id: "Vector",
                        d: "M15.58 11.9999C15.58 13.9799 13.98 15.5799 12 15.5799C10.02 15.5799 8.41998 13.9799 8.41998 11.9999C8.41998 10.0199 10.02 8.41992 12 8.41992C13.98 8.41992 15.58 10.0199 15.58 11.9999Z",
                        "stroke-width": "1.5",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round"
                      }),
                      _createElementVNode("path", {
                        id: "Vector_2",
                        d: "M12 20.2702C15.53 20.2702 18.82 18.1902 21.11 14.5902C22.01 13.1802 22.01 10.8102 21.11 9.40021C18.82 5.80021 15.53 3.72021 12 3.72021C8.47003 3.72021 5.18003 5.80021 2.89003 9.40021C1.99003 10.8102 1.99003 13.1802 2.89003 14.5902C5.18003 18.1902 8.47003 20.2702 12 20.2702Z",
                        "stroke-width": "1.5",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round"
                      })
                    ])
                  ], -1)
                ])))
              : (_openBlock(), _createElementBlock("svg", _hoisted_8, _cache[11] || (_cache[11] = [
                  _createStaticVNode("<path d=\"M14.5299 9.46992L9.46992 14.5299C8.81992 13.8799 8.41992 12.9899 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C12.9899 8.41992 13.8799 8.81992 14.5299 9.46992Z\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M17.8198 5.76998C16.0698 4.44998 14.0698 3.72998 11.9998 3.72998C8.46984 3.72998 5.17984 5.80998 2.88984 9.40998C1.98984 10.82 1.98984 13.19 2.88984 14.6C3.67984 15.84 4.59984 16.91 5.59984 17.77\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M8.41992 19.5299C9.55992 20.0099 10.7699 20.2699 11.9999 20.2699C15.5299 20.2699 18.8199 18.1899 21.1099 14.5899C22.0099 13.1799 22.0099 10.8099 21.1099 9.39993C20.7799 8.87993 20.4199 8.38993 20.0499 7.92993\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M15.5099 12.7C15.2499 14.11 14.0999 15.26 12.6899 15.52\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M9.47 14.53L2 22\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M21.9998 2L14.5298 9.47\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path>", 6)
                ])))
          ])
        ]),
        _createElementVNode("section", null, [
          _cache[15] || (_cache[15] = _createElementVNode("label", { for: "new-password" }, [
            _createElementVNode("strong", null, "Nova senha"),
            _createTextVNode(" (obrigatório)")
          ], -1)),
          _withDirectives(_createElementVNode("input", {
            autocomplete: "off",
            type: showNewPassword.value ? 'text':'password',
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((profilePayload.value.newPassword) = $event)),
            name: "new-password",
            id: "new-password"
          }, null, 8, _hoisted_9), [
            [_vModelDynamic, profilePayload.value.newPassword]
          ]),
          _createElementVNode("button", {
            type: "button",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (showNewPassword.value = !showNewPassword.value))
          }, [
            (showNewPassword.value)
              ? (_openBlock(), _createElementBlock("svg", _hoisted_10, _cache[13] || (_cache[13] = [
                  _createElementVNode("g", { id: "vuesax/linear/eye" }, [
                    _createElementVNode("g", { id: "eye" }, [
                      _createElementVNode("path", {
                        id: "Vector",
                        d: "M15.58 11.9999C15.58 13.9799 13.98 15.5799 12 15.5799C10.02 15.5799 8.41998 13.9799 8.41998 11.9999C8.41998 10.0199 10.02 8.41992 12 8.41992C13.98 8.41992 15.58 10.0199 15.58 11.9999Z",
                        "stroke-width": "1.5",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round"
                      }),
                      _createElementVNode("path", {
                        id: "Vector_2",
                        d: "M12 20.2702C15.53 20.2702 18.82 18.1902 21.11 14.5902C22.01 13.1802 22.01 10.8102 21.11 9.40021C18.82 5.80021 15.53 3.72021 12 3.72021C8.47003 3.72021 5.18003 5.80021 2.89003 9.40021C1.99003 10.8102 1.99003 13.1802 2.89003 14.5902C5.18003 18.1902 8.47003 20.2702 12 20.2702Z",
                        "stroke-width": "1.5",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round"
                      })
                    ])
                  ], -1)
                ])))
              : (_openBlock(), _createElementBlock("svg", _hoisted_11, _cache[14] || (_cache[14] = [
                  _createStaticVNode("<path d=\"M14.5299 9.46992L9.46992 14.5299C8.81992 13.8799 8.41992 12.9899 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C12.9899 8.41992 13.8799 8.81992 14.5299 9.46992Z\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M17.8198 5.76998C16.0698 4.44998 14.0698 3.72998 11.9998 3.72998C8.46984 3.72998 5.17984 5.80998 2.88984 9.40998C1.98984 10.82 1.98984 13.19 2.88984 14.6C3.67984 15.84 4.59984 16.91 5.59984 17.77\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M8.41992 19.5299C9.55992 20.0099 10.7699 20.2699 11.9999 20.2699C15.5299 20.2699 18.8199 18.1899 21.1099 14.5899C22.0099 13.1799 22.0099 10.8099 21.1099 9.39993C20.7799 8.87993 20.4199 8.38993 20.0499 7.92993\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M15.5099 12.7C15.2499 14.11 14.0999 15.26 12.6899 15.52\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M9.47 14.53L2 22\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M21.9998 2L14.5298 9.47\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path>", 6)
                ])))
          ])
        ]),
        _createElementVNode("section", null, [
          _cache[18] || (_cache[18] = _createElementVNode("label", { for: "repeat-new-password" }, [
            _createElementVNode("strong", null, "Repita a nova senha"),
            _createTextVNode(" (obrigatório)")
          ], -1)),
          _withDirectives(_createElementVNode("input", {
            autocomplete: "off",
            type: showRepeatNewPassword.value ? 'text':'password',
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((profilePayload.value.newPasswordConfirmation) = $event)),
            name: "repeat-new-password",
            id: "repeat-new-password"
          }, null, 8, _hoisted_12), [
            [_vModelDynamic, profilePayload.value.newPasswordConfirmation]
          ]),
          _createElementVNode("button", {
            type: "button",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (showRepeatNewPassword.value = !showRepeatNewPassword.value))
          }, [
            (showRepeatNewPassword.value)
              ? (_openBlock(), _createElementBlock("svg", _hoisted_13, _cache[16] || (_cache[16] = [
                  _createElementVNode("g", { id: "vuesax/linear/eye" }, [
                    _createElementVNode("g", { id: "eye" }, [
                      _createElementVNode("path", {
                        id: "Vector",
                        d: "M15.58 11.9999C15.58 13.9799 13.98 15.5799 12 15.5799C10.02 15.5799 8.41998 13.9799 8.41998 11.9999C8.41998 10.0199 10.02 8.41992 12 8.41992C13.98 8.41992 15.58 10.0199 15.58 11.9999Z",
                        "stroke-width": "1.5",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round"
                      }),
                      _createElementVNode("path", {
                        id: "Vector_2",
                        d: "M12 20.2702C15.53 20.2702 18.82 18.1902 21.11 14.5902C22.01 13.1802 22.01 10.8102 21.11 9.40021C18.82 5.80021 15.53 3.72021 12 3.72021C8.47003 3.72021 5.18003 5.80021 2.89003 9.40021C1.99003 10.8102 1.99003 13.1802 2.89003 14.5902C5.18003 18.1902 8.47003 20.2702 12 20.2702Z",
                        "stroke-width": "1.5",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round"
                      })
                    ])
                  ], -1)
                ])))
              : (_openBlock(), _createElementBlock("svg", _hoisted_14, _cache[17] || (_cache[17] = [
                  _createStaticVNode("<path d=\"M14.5299 9.46992L9.46992 14.5299C8.81992 13.8799 8.41992 12.9899 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C12.9899 8.41992 13.8799 8.81992 14.5299 9.46992Z\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M17.8198 5.76998C16.0698 4.44998 14.0698 3.72998 11.9998 3.72998C8.46984 3.72998 5.17984 5.80998 2.88984 9.40998C1.98984 10.82 1.98984 13.19 2.88984 14.6C3.67984 15.84 4.59984 16.91 5.59984 17.77\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M8.41992 19.5299C9.55992 20.0099 10.7699 20.2699 11.9999 20.2699C15.5299 20.2699 18.8199 18.1899 21.1099 14.5899C22.0099 13.1799 22.0099 10.8099 21.1099 9.39993C20.7799 8.87993 20.4199 8.38993 20.0499 7.92993\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M15.5099 12.7C15.2499 14.11 14.0999 15.26 12.6899 15.52\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M9.47 14.53L2 22\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M21.9998 2L14.5298 9.47\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path>", 6)
                ])))
          ])
        ])
      ]),
      _createElementVNode("nav", null, [
        (!isUpdating.value)
          ? (_openBlock(), _createElementBlock("input", {
              key: 0,
              autocomplete: "off",
              onClick: updateProfile,
              disabled: !profilePayload.value.newPassword || (profilePayload.value.newPassword && (profilePayload.value.newPassword != profilePayload.value.newPasswordConfirmation || profilePayload.value.newPassword?.length < 8)) ? true : false,
              type: "button",
              id: "profile-update-password",
              value: "Salvar alterações"
            }, null, 8, _hoisted_15))
          : (_openBlock(), _createBlock(SpinnerLoaderButton, {
              key: 1,
              height: 60,
              maxWidth: 464
            }))
      ])
    ])
  ]))
}
}

})