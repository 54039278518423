<template>
    <div :style="{ height: height, width: width, zIndex: zIndex}" :id="id ? id:''" class="transition-all overflow-auto duration-300 fixed bg-white box-shadow z-20 sm:rounded-xl modal left-1/2 -translate-x-1/2" :class="[isOpen ? ('open top-1/2 -translate-y-1/2 sm:bottom-0 opacity-100 ' + className ?? ''):('top-[-100%] opacity-0 '+className ?? '')]">
        <slot></slot>
    </div>
    <div class="fixed top-0 left-0 w-full bottom-0 h-full bg-black bg-opacity-50" :style="{zIndex:(zIndex ?? 2) - 1}" v-if="isOpen" @click="toggleModal(!isOpen)"></div>
</template>

<script setup lang="ts">
import { defineProps, watch, onMounted, onBeforeUnmount } from 'vue';

const props = defineProps({
  isOpen: { type: Boolean, required: true },
  height: String,
  width: String,
  toggleModal: { type: Function, required: true },
  zIndex: Number,
  className: String,
  id: {type:String, required: true},
});

const updateBodyOverflow = (isOpen: boolean) => {
  document.body.style.overflow = isOpen ? 'hidden' : '';
};

const onPopState = () => {
  if (props.isOpen && !window.history.state.modals?.includes(props.id)) {
    props.toggleModal(false);
  } else if(window.history.state.modals?.includes(props.id)){
    props.toggleModal(true);
  }
};

watch(() => props.isOpen, (newValue) => {
  updateBodyOverflow(newValue);
  if (newValue) {
    if(!window.history.state.modals?.includes(props.id)){
      window.history.pushState({ ...window.history.state, component: 'modal', modals: [...(window.history.state.modals ?? []), props.id] }, ''); // Push a new state to the history
    }
  } else{
    if(window.history.state.component == 'modal'){
      window.history.back()
    }
  }
});

onMounted(() => {
    window.addEventListener('popstate', onPopState);
});

onBeforeUnmount(() => {
  window.removeEventListener('popstate', onPopState);
});
</script>

<style lang="scss" scoped>
@media screen and (max-width:800px){
    .modal{
        height:auto !important;
        bottom:0;
        width:100% !important;
        left:0;
        right:0;
        transform:translate3d(0, 100vh, 0);
        top:0;
        opacity:0;
        transition: all .2s ease-out;
        border-radius:0;

        &.open{
            transform:translate3d(0, 0, 0);
            opacity:1
        }
    }
}
</style>