import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withKeys as _withKeys, vModelText as _vModelText, unref as _unref, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "font-inter flex flex-col gap-8 companies query-customer pt-12 sm:pt-6" }
const _hoisted_2 = { class: "grid grid-cols-12 query-customer__filter gap-6" }
const _hoisted_3 = { class: "query-customer__filter__search flex col-span-12" }
const _hoisted_4 = {
  key: 0,
  class: "flex flex-col items-center gap-4 justify-center"
}
const _hoisted_5 = {
  key: 1,
  class: "flex items-center justify-center flex-col gap-2"
}
const _hoisted_6 = {
  key: 0,
  class: "font-bold"
}
const _hoisted_7 = {
  key: 1,
  class: "flex flex-col"
}

import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";
import { ref } from "vue";
import axiosClient from "@/api/axios";
import { vMaska } from "maska/vue";
import SpinnerLoader from "@/components/loader/SpinnerLoader.vue";
import MyModal from "@/components/modal/MyModal.vue";
import { toast } from "vue3-toastify";


export default /*@__PURE__*/_defineComponent({
  __name: 'QueryCustomerView',
  setup(__props) {

const search = ref("");
const percentage = ref();
const isLoading = ref(false);
const notFound = ref(false);
const isCreateBlockingReasonModalOpen = ref(false);
const reportPayload = ref<Record<string, any>>({});
const isReportRequestLoading = ref(false);

function getCustomer() {
  isLoading.value = true;

  axiosClient
    .get(`/customers/${search.value.replace(/[-\/.]/g, "")}`)
    .then((res) => {
      percentage.value = res.data.percentage;
      notFound.value = false;
    })
    .catch(() => {
      notFound.value = true;
    })
    .finally(() => (isLoading.value = false));
}

function reportCustomer() {
  isReportRequestLoading.value = true;

  axiosClient
    .post("/companies/report", {
      reason: reportPayload.value.reason,
      document: search.value.replace(/[\-\.\//]/g, ""),
    })
    .then(() => {
      reportPayload.value = {};
      isCreateBlockingReasonModalOpen.value = false;
      toast.success("Denúncia cadastrada com sucesso");
    })
    .finally(() => (isReportRequestLoading.value = false));
}

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[7] || (_cache[7] = _createElementVNode("div", null, [
      _createElementVNode("h1", { class: "text-3xl font-bold pb-2 text-292 font-nunito" }, " Consultar cliente "),
      _createElementVNode("p", { class: "text-gray-500 text-sm" }, "Consulte pelo CPF ou CNPJ do cliente")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("form", {
          onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
          class: "rounded-xl w-full relative"
        }, [
          _withDirectives(_createElementVNode("input", {
            type: "tel",
            placeholder: "Digite um CPF ou CNPJ",
            class: "rounded-tl-xl rounded-bl-xl input-primary w-full h-full p-4 py-3 bg-f5 pl-11 border",
            onKeydown: _withKeys(getCustomer, ["enter"]),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((search).value = $event))
          }, null, 544), [
            [_vModelText, search.value],
            [_unref(vMaska), 
              search.value.length <= 14 ? '###.###.###-###' : '##.###.###/####-##'
            ]
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("svg", {
            class: "absolute top-1/2 -translate-y-1/2 left-4",
            xmlns: "http://www.w3.org/2000/svg",
            width: "16",
            height: "16",
            viewBox: "0 0 16 16",
            fill: "none"
          }, [
            _createElementVNode("path", {
              d: "M0.0247006 7.87823C0.162935 9.46532 0.887052 10.9532 2.06688 12.0735C3.24671 13.1938 4.80475 13.8732 6.46033 13.9891C8.11592 14.1051 9.76012 13.6501 11.0972 12.7061L14.2375 15.7092C14.4312 15.8952 14.6938 15.9996 14.9677 15.9996C15.2415 15.9996 15.5043 15.8953 15.6978 15.7093C15.8915 15.5235 16.0002 15.2714 16 15.0085C16.0002 14.7458 15.8915 14.4937 15.698 14.3078L12.5681 11.2947C13.9283 9.5308 14.2998 7.23853 13.5616 5.1613C12.8236 3.08439 11.0702 1.48725 8.86994 0.888013C6.66989 0.288729 4.30337 0.763496 2.53789 2.15874C0.772543 3.55382 -0.16685 5.69131 0.0244658 7.87791L0.0247006 7.87823ZM6.96867 2.62946C8.26895 2.62946 9.51591 3.12511 10.4353 4.00726C11.3547 4.88941 11.8713 6.08607 11.8713 7.33363C11.8713 8.58119 11.3547 9.77768 10.4353 10.6598C9.51591 11.542 8.2689 12.0376 6.96867 12.0376C5.66844 12.0376 4.42124 11.542 3.50184 10.6598C2.58245 9.77768 2.06587 8.58119 2.06587 7.33363C2.06587 6.08607 2.58245 4.88941 3.50184 4.00726C4.42124 3.12511 5.66844 2.62946 6.96867 2.62946Z",
              fill: "#676767"
            })
          ], -1))
        ], 32),
        _createElementVNode("button", {
          onClick: getCustomer,
          class: "rounded-tr-lg rounded-br-lg font-inter text-white font-bold"
        }, " pesquisar ")
      ])
    ]),
    _createElementVNode("section", null, [
      (percentage.value !== undefined && !notFound.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_unref(CircleProgress), {
              percent: percentage.value,
              "show-percent": true
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode(" 100% ")
              ])),
              _: 1
            }, 8, ["percent"])
          ]))
        : _createCommentVNode("", true),
      (
          (percentage.value == undefined && !isLoading.value) || (notFound.value && !isLoading.value)
        )
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _cache[6] || (_cache[6] = _createStaticVNode("<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"104\" height=\"104\" viewBox=\"0 0 24 24\" fill=\"none\" data-v-ba475710><path d=\"M12 12.75C8.83 12.75 6.25 10.17 6.25 7C6.25 3.83 8.83 1.25 12 1.25C15.17 1.25 17.75 3.83 17.75 7C17.75 10.17 15.17 12.75 12 12.75ZM12 2.75C9.66 2.75 7.75 4.66 7.75 7C7.75 9.34 9.66 11.25 12 11.25C14.34 11.25 16.25 9.34 16.25 7C16.25 4.66 14.34 2.75 12 2.75Z\" fill=\"#171717\" data-v-ba475710></path><path d=\"M3.41016 22.75C3.00016 22.75 2.66016 22.41 2.66016 22C2.66016 17.73 6.85018 14.25 12.0002 14.25C12.4102 14.25 12.7502 14.59 12.7502 15C12.7502 15.41 12.4102 15.75 12.0002 15.75C7.68018 15.75 4.16016 18.55 4.16016 22C4.16016 22.41 3.82016 22.75 3.41016 22.75Z\" fill=\"#171717\" data-v-ba475710></path><path d=\"M18.2 22.15C16.02 22.15 14.25 20.38 14.25 18.2C14.25 16.02 16.02 14.25 18.2 14.25C20.38 14.25 22.15 16.02 22.15 18.2C22.15 20.38 20.38 22.15 18.2 22.15ZM18.2 15.75C16.85 15.75 15.75 16.85 15.75 18.2C15.75 19.55 16.85 20.65 18.2 20.65C19.55 20.65 20.65 19.55 20.65 18.2C20.65 16.85 19.55 15.75 18.2 15.75Z\" fill=\"#171717\" data-v-ba475710></path><path d=\"M22 22.75C21.81 22.75 21.6199 22.68 21.4699 22.53L20.4699 21.53C20.1799 21.24 20.1799 20.7599 20.4699 20.4699C20.7599 20.1799 21.24 20.1799 21.53 20.4699L22.53 21.4699C22.82 21.7599 22.82 22.24 22.53 22.53C22.38 22.68 22.19 22.75 22 22.75Z\" fill=\"#171717\" data-v-ba475710></path></svg>", 1)),
            (!notFound.value)
              ? (_openBlock(), _createElementBlock("p", _hoisted_6, " Digite e aperte enter para fazer uma busca "))
              : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _cache[5] || (_cache[5] = _createElementVNode("p", { class: "text-yellow-500 pb-2" }, "Esta empresa não está cadastrada", -1)),
                  _createVNode(_component_RouterLink, {
                    to: { name: 'customers.create', query: { document: search.value } },
                    class: "border border-emerald-600 border-2 rounded-3xl px-4 py-2 font-bold text-emerald-600 flex items-center gap-2 add mx-auto"
                  }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createElementVNode("svg", {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "24",
                        height: "24",
                        viewBox: "0 0 24 24",
                        fill: "none"
                      }, [
                        _createElementVNode("path", {
                          d: "M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z",
                          stroke: "#171717",
                          class: "stroke-emerald-600",
                          "stroke-width": "1.5",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round"
                        }),
                        _createElementVNode("path", {
                          d: "M8 12H16",
                          stroke: "#171717",
                          "stroke-width": "1.5",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                          class: "stroke-emerald-600"
                        }),
                        _createElementVNode("path", {
                          d: "M12 16V8",
                          stroke: "#171717",
                          "stroke-width": "1.5",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                          class: "stroke-emerald-600"
                        })
                      ], -1),
                      _createTextVNode(" Adicionar ")
                    ])),
                    _: 1
                  }, 8, ["to"])
                ]))
          ]))
        : _createCommentVNode("", true),
      (isLoading.value)
        ? (_openBlock(), _createBlock(SpinnerLoader, { key: 2 }))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})