import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/fraude_zero.png'


const _hoisted_1 = { class: "main-layout" }
const _hoisted_2 = { class: "flex flex-col gap-8" }
const _hoisted_3 = { class: "flex flex-col gap-4" }
const _hoisted_4 = { class: "h-full main" }
const _hoisted_5 = {
  id: "view",
  style: {"width":"80rem"}
}

import { isSidebarOpen } from "@/global/states/GlobalState";
import useCookie from "@/composables/useCookie";
import { onMounted, ref } from "vue";
import MyModal from "@/components/modal/MyModal.vue";
import ScanTwoFAQrCodeModal from "./components/ScanTwoFAQrCodeModal.vue";
import ActiveTwoFA from "./components/ActiveTwoFA.vue";
import ContractTerms from "./components/ContractTerms.vue";
import axiosClient from "@/api/axios";


export default /*@__PURE__*/_defineComponent({
  __name: 'MainLayout',
  setup(__props) {

const { getCookie, removeCookie } = useCookie();
const isCompaniesMenuOpen = ref(false);
const isContractTermsModalOpen = ref(false);
const is2FAuthenticationQrCodeModalOpen = ref(false);
const isActivateTwoFAModalOpen = ref(false);
const authenticatedUser = ref<any>(
  getCookie("authenticated_user")
    ? JSON.parse(getCookie("authenticated_user") as string)
    : { address: {} }
);
const qrCode = ref("");

onMounted(() => {
  verifyAuthCookie();
  get2af();
});

function verifyAuthCookie() {
  if (!getCookie("auth_token")) {
    window.location.href = process.env?.VUE_APP_LOGIN_URL;
  } else if (!localStorage.getItem("contract_accepted")) {
    isContractTermsModalOpen.value = true;
  }
}

function get2af() {
  if (
    getCookie("is2AF") !== "true" &&
    !localStorage.getItem("hasValidated2AF")
  ) {
    is2FAuthenticationQrCodeModalOpen.value = true;
    axiosClient.post("accounts/2af").then((res) => {
      qrCode.value = res.data.qrCode;
    });
  } else if (!localStorage.getItem("hasValidated2AF")) {
    isActivateTwoFAModalOpen.value = true;
  }
}

function acceptContract() {
  localStorage.setItem("contract_accepted", "true");
  isContractTermsModalOpen.value = false;
}

function refuseContract() {
  isContractTermsModalOpen.value = false;
  removeCookie("auth_token");
  removeCookie("authenticated_user");
  window.location.href = process.env?.VUE_APP_LOGIN_URL;
}

function logout() {
  removeCookie("auth_token");
  removeCookie("authenticated_user");
  removeCookie("is2AF");
  localStorage.removeItem("hasValidated2AF");
  window.location.href = process.env?.VUE_APP_LOGIN_URL;
}

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("aside", {
      class: _normalizeClass(["main-layout__sidebar font-inter p-4 flex flex-col gap-6", _unref(isSidebarOpen) ? 'open' : ''])
    }, [
      _cache[11] || (_cache[11] = _createElementVNode("div", { class: "flex items-center gap-4 pb-5 pt-4" }, [
        _createElementVNode("img", {
          class: "main-layout__sidebar--logo",
          src: _imports_0
        })
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createVNode(_component_RouterLink, {
            to: { name: 'companies.query' },
            class: _normalizeClass(["flex items-center justify-between gap-4 hover:stroke-primary cursor-pointer", 
              _ctx.$route.path.startsWith('/companies') || isCompaniesMenuOpen.value
                ? 'router-link-active'
                : ''
            ]),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (isSidebarOpen.value = false))
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createElementVNode("div", { class: "flex items-center gap-4" }, [
                _createElementVNode("svg", {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "24",
                  height: "24",
                  viewBox: "0 0 24 24",
                  fill: "none"
                }, [
                  _createElementVNode("path", {
                    d: "M3.00977 11.22V15.71C3.00977 20.2 4.80977 22 9.29977 22H14.6898C19.1798 22 20.9798 20.2 20.9798 15.71V11.22",
                    stroke: "#171717",
                    "stroke-width": "1.5",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    class: "hover:parent:stroke-primary"
                  }),
                  _createElementVNode("path", {
                    d: "M12 12C13.83 12 15.18 10.51 15 8.68L14.34 2H9.66999L8.99999 8.68C8.81999 10.51 10.17 12 12 12Z",
                    stroke: "#171717",
                    "stroke-width": "1.5",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    class: "hover:parent:stroke-primary"
                  }),
                  _createElementVNode("path", {
                    d: "M18.3098 12C20.3298 12 21.8098 10.36 21.6098 8.35L21.3298 5.6C20.9698 3 19.9698 2 17.3498 2H14.2998L14.9998 9.01C15.1698 10.66 16.6598 12 18.3098 12Z",
                    stroke: "#171717",
                    "stroke-width": "1.5",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    class: "hover:parent:stroke-primary"
                  }),
                  _createElementVNode("path", {
                    d: "M5.63988 12C7.28988 12 8.77988 10.66 8.93988 9.01L9.15988 6.8L9.63988 2H6.58988C3.96988 2 2.96988 3 2.60988 5.6L2.33988 8.35C2.13988 10.36 3.61988 12 5.63988 12Z",
                    stroke: "#171717",
                    "stroke-width": "1.5",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    class: "hover:parent:stroke-primary"
                  }),
                  _createElementVNode("path", {
                    d: "M12 17C10.33 17 9.5 17.83 9.5 19.5V22H14.5V19.5C14.5 17.83 13.67 17 12 17Z",
                    stroke: "#171717",
                    "stroke-width": "1.5",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    class: "hover:parent:stroke-primary"
                  })
                ]),
                _createTextVNode(" Consultar empresa ")
              ], -1)
            ])),
            _: 1
          }, 8, ["class"])
        ])
      ]),
      _createElementVNode("div", null, [
        _cache[10] || (_cache[10] = _createElementVNode("h6", null, "Seus dados", -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", null, [
            _createVNode(_component_RouterLink, {
              to: { name: 'profile' },
              class: _normalizeClass(["flex items-center justify-between gap-4 hover:stroke-primary cursor-pointer", 
                _ctx.$route.path.startsWith('/profile') ? 'router-link-active' : ''
              ]),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (isSidebarOpen.value = false))
            }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createElementVNode("div", { class: "flex items-center gap-4" }, [
                  _createElementVNode("svg", {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "24",
                    height: "24",
                    viewBox: "0 0 24 24",
                    fill: "none"
                  }, [
                    _createElementVNode("path", {
                      d: "M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z",
                      stroke: "#171717",
                      "stroke-width": "1.5",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      class: "hover:parent:stroke-primary"
                    }),
                    _createElementVNode("path", {
                      d: "M20.5899 22C20.5899 18.13 16.7399 15 11.9999 15C7.25991 15 3.40991 18.13 3.40991 22",
                      stroke: "#171717",
                      "stroke-width": "1.5",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      class: "hover:parent:stroke-primary"
                    })
                  ]),
                  _createTextVNode(" Perfil ")
                ], -1)
              ])),
              _: 1
            }, 8, ["class"])
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_RouterLink, {
              to: { name: 'credentials' },
              class: _normalizeClass(["flex items-center justify-between gap-4 hover:stroke-primary cursor-pointer hover:stroke-primary", 
                _ctx.$route.path.startsWith('/credentials') ? 'router-link-active' : ''
              ]),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (isSidebarOpen.value = false))
            }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createElementVNode("div", { class: "flex items-center gap-4" }, [
                  _createElementVNode("svg", {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "24",
                    height: "24",
                    viewBox: "0 0 24 24",
                    fill: "none"
                  }, [
                    _createElementVNode("path", {
                      d: "M19.7901 14.9301C17.7301 16.9801 14.7801 17.6101 12.1901 16.8001L7.48015 21.5001C7.14015 21.8501 6.47015 22.0601 5.99015 21.9901L3.81015 21.6901C3.09015 21.5901 2.42015 20.9101 2.31015 20.1901L2.01015 18.0101C1.94015 17.5301 2.17015 16.8601 2.50015 16.5201L7.20015 11.8201C6.40015 9.22007 7.02015 6.27007 9.08015 4.22007C12.0301 1.27007 16.8201 1.27007 19.7801 4.22007C22.7401 7.17007 22.7401 11.9801 19.7901 14.9301Z",
                      stroke: "#171717",
                      "stroke-width": "1.5",
                      "stroke-miterlimit": "10",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      class: "hover:parent:stroke-primary"
                    }),
                    _createElementVNode("path", {
                      d: "M6.89014 17.49L9.19014 19.79",
                      stroke: "#171717",
                      "stroke-width": "1.5",
                      "stroke-miterlimit": "10",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      class: "hover:parent:stroke-primary"
                    }),
                    _createElementVNode("path", {
                      d: "M14.5 11C15.3284 11 16 10.3284 16 9.5C16 8.67157 15.3284 8 14.5 8C13.6716 8 13 8.67157 13 9.5C13 10.3284 13.6716 11 14.5 11Z",
                      stroke: "#171717",
                      "stroke-width": "1.5",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      class: "hover:parent:stroke-primary"
                    })
                  ]),
                  _createTextVNode(" Credenciais ")
                ], -1)
              ])),
              _: 1
            }, 8, ["class"])
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("a", {
              onClick: logout,
              class: "flex justify-between items-center gap-4 cursor-pointer hover:stroke-primary"
            }, _cache[9] || (_cache[9] = [
              _createStaticVNode("<div class=\"flex items-center gap-4\"><svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\"><path d=\"M8.90002 7.55999C9.21002 3.95999 11.06 2.48999 15.11 2.48999H15.24C19.71 2.48999 21.5 4.27999 21.5 8.74999V15.27C21.5 19.74 19.71 21.53 15.24 21.53H15.11C11.09 21.53 9.24002 20.08 8.91002 16.54\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" class=\"hover:parent:stroke-primary\"></path><path d=\"M15 12H3.62\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" class=\"hover:parent:stroke-primary\"></path><path d=\"M5.85 8.65002L2.5 12L5.85 15.35\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" class=\"hover:parent:stroke-primary\"></path></svg> Sair </div>", 1)
            ]))
          ])
        ])
      ])
    ], 2),
    _createElementVNode("main", _hoisted_4, [
      _createElementVNode("button", {
        onClick: _cache[3] || (_cache[3] = ($event: any) => (isSidebarOpen.value = !_unref(isSidebarOpen))),
        class: "hidden fixed top-4 left-4 open-sidebar-btn"
      }, _cache[12] || (_cache[12] = [
        _createElementVNode("svg", {
          xmlns: "http://www.w3.org/2000/svg",
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none"
        }, [
          _createElementVNode("path", {
            d: "M3 7H21",
            stroke: "#171717",
            "stroke-width": "1.5",
            "stroke-linecap": "round"
          }),
          _createElementVNode("path", {
            d: "M3 12H21",
            stroke: "#171717",
            "stroke-width": "1.5",
            "stroke-linecap": "round"
          }),
          _createElementVNode("path", {
            d: "M3 17H21",
            stroke: "#171717",
            "stroke-width": "1.5",
            "stroke-linecap": "round"
          })
        ], -1)
      ])),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_RouterView)
      ])
    ]),
    (authenticatedUser.value)
      ? (_openBlock(), _createBlock(MyModal, {
          key: 0,
          isOpen: isContractTermsModalOpen.value,
          toggleModal: (v) => !v ? refuseContract() : '',
          id: 'contract-terms',
          width: '600px',
          height: '800px'
        }, {
          default: _withCtx(() => [
            _createVNode(ContractTerms, {
              authenticatedUser: authenticatedUser.value,
              onOnAccept: acceptContract,
              onOnRefuse: refuseContract
            }, null, 8, ["authenticatedUser"])
          ]),
          _: 1
        }, 8, ["isOpen", "toggleModal"]))
      : _createCommentVNode("", true),
    _createVNode(MyModal, {
      height: '610px',
      isOpen: is2FAuthenticationQrCodeModalOpen.value,
      toggleModal: (v) => { },
      id: 'ScanTwoFAQrCodeModal',
      zIndex: 40
    }, {
      default: _withCtx(() => [
        _createVNode(ScanTwoFAQrCodeModal, {
          qrCode: qrCode.value,
          onOnFirstCodeConfirmation: _cache[4] || (_cache[4] = ($event: any) => (is2FAuthenticationQrCodeModalOpen.value = false))
        }, null, 8, ["qrCode"])
      ]),
      _: 1
    }, 8, ["isOpen"]),
    _createVNode(MyModal, {
      height: '385px',
      width: '400px',
      isOpen: isActivateTwoFAModalOpen.value,
      toggleModal: (v) => {},
      id: 'ActiveTwoFA',
      zIndex: 40
    }, {
      default: _withCtx(() => [
        _createVNode(ActiveTwoFA, {
          onOnCodeValidated: _cache[5] || (_cache[5] = ($event: any) => (isActivateTwoFAModalOpen.value = false))
        })
      ]),
      _: 1
    }, 8, ["isOpen"])
  ]))
}
}

})