<template>
<div class="simple-spinner">
    <span></span>
</div>
</template>

<style scoped lang="scss">
.simple-spinner {
    width: 30px;
    height: 30px;
}

.simple-spinner span {
    display: block;
    width: 30px;
    height: 30px;
    border: 3px solid transparent;
    border-radius: 50%;
    border-right-color: $primary-color;
    animation: spinner-anim 0.8s linear infinite;
}

@keyframes spinner-anim {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>