import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "font-inter p-4 sm:p-6" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  class: "py-10 my-10 flex justify-center"
}
const _hoisted_4 = ["disabled"]

import SpinnerLoader from '@/components/loader/SpinnerLoader.vue'
import axiosClient from '@/api/axios'
import { ref } from 'vue'
import SpinnerLoaderButton from '@/components/loader/SpinnerLoaderButton.vue'
import { toast } from 'vue3-toastify';



export default /*@__PURE__*/_defineComponent({
  __name: 'ScanTwoFAQrCodeModal',
  props: {
    qrCode: {}
  },
  emits: ['onFirstCodeConfirmation'],
  setup(__props: any, { emit: __emit }) {

const emit = __emit


const code = ref('')
const isLoading = ref(false)

function confirmFirstCode() {
  isLoading.value = true

  axiosClient.put('/accounts/2af', { code: code.value.replaceAll(' ', '') })
    .then((res) => {
      if (res.data.verified) {
        const domain = '.' + process.env?.VUE_APP_DOMAIN;
        document.cookie = `authenticated_user=${encodeURIComponent(JSON.stringify(res.data.user))}; path=/; domain=${domain}; secure; SameSite=Lax`;
        document.cookie = `auth_token=${encodeURIComponent(res.data.access_token)}; path=/; domain=${domain}; secure; SameSite=Lax`;
        localStorage.setItem('hasValidated2AF', 'true')
        toast.success('Código validado')
        emit('onFirstCodeConfirmation')
      }
    })
    .catch(() => toast.error('Código inválido'))
    .finally(() => isLoading.value = false)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "text-3xl font-bold text-center" }, "Autenticação 2Fatores", -1)),
    _cache[2] || (_cache[2] = _createElementVNode("p", null, "1. Abra o app Authenticator adicione uma nova conta.", -1)),
    _cache[3] || (_cache[3] = _createElementVNode("p", null, "2. Leia o QrCode exibido abaixo usando o Authenticator", -1)),
    _cache[4] || (_cache[4] = _createElementVNode("p", null, "3. Digite o código de 6 dígitos do app.", -1)),
    _cache[5] || (_cache[5] = _createElementVNode("p", null, "4. Pronto! A autenticação está configurada.", -1)),
    (_ctx.qrCode)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "mx-auto mt-6",
          src: _ctx.qrCode
        }, null, 8, _hoisted_2))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(SpinnerLoader)
        ])),
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((code).value = $event)),
      type: "password",
      placeholder: "Digite o código",
      class: "my-6"
    }, null, 512), [
      [_vModelText, code.value]
    ]),
    (!isLoading.value)
      ? (_openBlock(), _createElementBlock("button", {
          key: 2,
          disabled: code.value.length < 6,
          onClick: confirmFirstCode
        }, "Verificar", 8, _hoisted_4))
      : (_openBlock(), _createBlock(SpinnerLoaderButton, {
          key: 3,
          height: 60,
          maxWidth: 464
        }))
  ]))
}
}

})