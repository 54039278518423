import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["id"]

import { watch, onMounted, onBeforeUnmount } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'MyModal',
  props: {
  isOpen: { type: Boolean, required: true },
  height: String,
  width: String,
  toggleModal: { type: Function, required: true },
  zIndex: Number,
  className: String,
  id: {type:String, required: true},
},
  setup(__props) {

const props = __props;

const updateBodyOverflow = (isOpen: boolean) => {
  document.body.style.overflow = isOpen ? 'hidden' : '';
};

const onPopState = () => {
  if (props.isOpen && !window.history.state.modals?.includes(props.id)) {
    props.toggleModal(false);
  } else if(window.history.state.modals?.includes(props.id)){
    props.toggleModal(true);
  }
};

watch(() => props.isOpen, (newValue) => {
  updateBodyOverflow(newValue);
  if (newValue) {
    if(!window.history.state.modals?.includes(props.id)){
      window.history.pushState({ ...window.history.state, component: 'modal', modals: [...(window.history.state.modals ?? []), props.id] }, ''); // Push a new state to the history
    }
  } else{
    if(window.history.state.component == 'modal'){
      window.history.back()
    }
  }
});

onMounted(() => {
    window.addEventListener('popstate', onPopState);
});

onBeforeUnmount(() => {
  window.removeEventListener('popstate', onPopState);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      style: _normalizeStyle({ height: __props.height, width: __props.width, zIndex: __props.zIndex}),
      id: __props.id ? __props.id:'',
      class: _normalizeClass(["transition-all overflow-auto duration-300 fixed bg-white box-shadow z-20 sm:rounded-xl modal left-1/2 -translate-x-1/2", [__props.isOpen ? ('open top-1/2 -translate-y-1/2 sm:bottom-0 opacity-100 ' + __props.className ?? ''):('top-[-100%] opacity-0 '+__props.className ?? '')]])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 14, _hoisted_1),
    (__props.isOpen)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "fixed top-0 left-0 w-full bottom-0 h-full bg-black bg-opacity-50",
          style: _normalizeStyle({zIndex:(__props.zIndex ?? 2) - 1}),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (__props.toggleModal(!__props.isOpen)))
        }, null, 4))
      : _createCommentVNode("", true)
  ], 64))
}
}

})