import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"




export default /*@__PURE__*/_defineComponent({
  __name: 'SpinnerLoaderButton',
  props: {
  height: {
    type: Number,
    default: null
  },
  maxWidth: {
    type: Number,
    default: null
  },
  isButton: {
    type: Boolean,
    default: true
  }
},
  setup(__props) {

const props = __props

const spinnerStyles = {
  height: props.height ? props.height + 'px' : 'unset',
  maxWidth: props.maxWidth ? props.maxWidth + 'px' : 'unset'
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass([{ 'not-button': !__props.isButton }, "simple-spinner"]),
    style: spinnerStyles
  }, _cache[0] || (_cache[0] = [
    _createElementVNode("span", { class: "loader" }, null, -1)
  ]), 2))
}
}

})