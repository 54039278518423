import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, unref as _unref, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "create-customer pt-12 sm:pt-6" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "grid grid-cols-12 gap-4" }
const _hoisted_4 = { class: "col-span-12 sm:col-span-6 flex flex-col gap-4" }
const _hoisted_5 = { class: "flex flex-col gap-4 grid grid-cols-12" }
const _hoisted_6 = { class: "font-inter flex flex-col gap-2 sm:col-span-6 col-span-12 text-sm" }
const _hoisted_7 = { class: "font-inter flex flex-col gap-2 sm:col-span-6 col-span-12 text-sm" }
const _hoisted_8 = { class: "font-inter flex flex-col gap-2 sm:col-span-6 col-span-12 text-sm" }
const _hoisted_9 = { class: "font-inter flex flex-col gap-2 sm:col-span-6 col-span-12 text-sm" }
const _hoisted_10 = { class: "font-inter flex flex-col gap-2 sm:col-span-6 col-span-12 text-sm" }
const _hoisted_11 = { class: "font-inter flex flex-col gap-2 sm:col-span-6 col-span-12 text-sm" }
const _hoisted_12 = { class: "font-inter flex flex-col gap-2 sm:col-span-6 col-span-12 text-sm" }
const _hoisted_13 = { class: "font-inter flex flex-col gap-2 sm:col-span-6 col-span-12 text-sm" }
const _hoisted_14 = { class: "font-inter flex flex-col gap-2 sm:col-span-6 col-span-12 text-sm" }
const _hoisted_15 = { class: "font-inter flex flex-col gap-2 sm:col-span-6 col-span-12 text-sm" }
const _hoisted_16 = { class: "font-inter flex flex-col gap-2 sm:col-span-6 col-span-12 text-sm" }
const _hoisted_17 = { class: "col-span-12 sm:col-span-6 flex flex-col gap-2" }
const _hoisted_18 = { class: "flex flex-col gap-2" }
const _hoisted_19 = { class: "flex flex-col gap-4 py-4" }
const _hoisted_20 = { class: "font-inter flex flex-col gap-2 col-span-6" }
const _hoisted_21 = ["disabled"]

import { ref, computed } from "vue";
import { vMaska } from "maska/vue";
import { isLoading } from "@/global/states/GlobalState";
import SpinnerLoader from "@/components/loader/SpinnerLoader.vue";
import axiosClient from "@/api/axios";
import { useRoute } from "vue-router";
import { toast } from "vue3-toastify";
import ICustomer from "@/interfaces/customers/ICustomer";


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateCustomerView',
  setup(__props) {

const route = useRoute();
const payload = ref<Partial<ICustomer & { complaint_reason: string }>>({
  document: route.query.document as string,
  address: {},
});
const isAddressValid = computed(() => {
  if (payload.value.address?.postal_code) {
    if (payload.value.address?.postal_code.length !== 9) {
      return false;
    }

    return (
      payload.value.address.street &&
      payload.value.address.number &&
      payload.value.address.neighborhood &&
      payload.value.address.city &&
      payload.value.address.state
    );
  }

  return true;
});

function createCustomer() {
  isLoading.value = true;

  axiosClient
    .post("/customers", payload.value)
    .then(() => {
      payload.value = { address: {} };
      toast.success("Cliente cadastrado com sucesso");
    })
    .finally(() => (isLoading.value = false));
}

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_RouterLink, {
        to: { name: 'companies.query' },
        class: "flex items-center gap-2 mb-8 go-back"
      }, {
        default: _withCtx(() => _cache[13] || (_cache[13] = [
          _createElementVNode("div", { class: "bg-zinc-100 p-3 rounded-full go-back__icon-wrapper" }, [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              width: "24",
              height: "24",
              viewBox: "0 0 24 24",
              fill: "none"
            }, [
              _createElementVNode("path", {
                d: "M9.57 5.92969L3.5 11.9997L9.57 18.0697M20.5 11.9997H3.67",
                stroke: "#090909",
                "stroke-width": "1.5",
                "stroke-miterlimit": "10",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              })
            ])
          ], -1),
          _createElementVNode("h1", { class: "text-3xl font-bold font-nunito" }, "Cadastrar cliente", -1)
        ])),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("section", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _cache[14] || (_cache[14] = _createElementVNode("label", { for: "" }, [
              _createElementVNode("strong", { class: "text-lg" }, "CPF/CNPJ"),
              _createTextVNode(" (obrigatório)")
            ], -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              placeholder: "ex: 000.000.000-00",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((payload.value.document) = $event))
            }, null, 512), [
              [_unref(vMaska), 
                (payload.value.document ?? '').length <= 14
                  ? '###.###.###-###'
                  : '##.###.###/####-##'
              ],
              [_vModelText, payload.value.document]
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[15] || (_cache[15] = _createElementVNode("label", { for: "" }, [
              _createElementVNode("strong", { class: "text-lg" }, "Nome"),
              _createTextVNode(" (obrigatório)")
            ], -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              placeholder: "ex: João",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((payload.value.name) = $event))
            }, null, 512), [
              [_vModelText, payload.value.name]
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _cache[16] || (_cache[16] = _createElementVNode("label", { for: "" }, [
              _createElementVNode("strong", { class: "text-lg" }, "Email"),
              _createTextVNode(" (obrigatório)")
            ], -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              placeholder: "ex: email@gmail.com",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((payload.value.email) = $event))
            }, null, 512), [
              [_vModelText, payload.value.email]
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _cache[17] || (_cache[17] = _createElementVNode("label", { for: "" }, [
              _createElementVNode("strong", { class: "text-lg" }, "Telefone"),
              _createTextVNode(" (obrigatório)")
            ], -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              placeholder: "ex: (99) 9 9999-9999",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((payload.value.phone_number) = $event))
            }, null, 512), [
              [_vModelText, payload.value.phone_number],
              [_unref(vMaska), '(##) # ####-####']
            ])
          ]),
          _cache[25] || (_cache[25] = _createElementVNode("div", { class: "col-span-12 pt-4" }, [
            _createElementVNode("h2", { class: "text-xl font-inter" }, [
              _createElementVNode("strong", null, "Endereço do cliente"),
              _createElementVNode("small", { class: "text-md" }, "(opcional)")
            ]),
            _createElementVNode("p", { class: "text-6f font-inter text-sm" }, " Adicione o máximo de informações do endereço do cliente. ")
          ], -1)),
          _createElementVNode("div", _hoisted_10, [
            _cache[18] || (_cache[18] = _createElementVNode("label", { for: "" }, [
              _createElementVNode("strong", { class: "text-lg" }, "Cep"),
              _createTextVNode(" (obrigatório)")
            ], -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              placeholder: "ex: 99999-999",
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((payload.value.address!.postal_code) = $event))
            }, null, 512), [
              [_unref(vMaska), '#####-###'],
              [_vModelText, payload.value.address!.postal_code]
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _cache[19] || (_cache[19] = _createElementVNode("label", { for: "" }, [
              _createElementVNode("strong", { class: "text-lg" }, "Rua"),
              _createTextVNode(" (obrigatório)")
            ], -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              placeholder: "ex: Rua josé...",
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((payload.value.address!.street) = $event))
            }, null, 512), [
              [_vModelText, payload.value.address!.street]
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _cache[20] || (_cache[20] = _createElementVNode("label", { for: "" }, [
              _createElementVNode("strong", { class: "text-lg" }, "Número"),
              _createTextVNode(" (obrigatório)")
            ], -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              placeholder: "ex: 2341",
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((payload.value.address!.number) = $event))
            }, null, 512), [
              [_vModelText, payload.value.address!.number]
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _cache[21] || (_cache[21] = _createElementVNode("label", { for: "" }, [
              _createElementVNode("strong", { class: "text-lg" }, "Bairro"),
              _createTextVNode(" (obrigatório)")
            ], -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              placeholder: "ex: Aldeota",
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((payload.value.address!.neighborhood) = $event))
            }, null, 512), [
              [_vModelText, payload.value.address!.neighborhood]
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _cache[22] || (_cache[22] = _createElementVNode("label", { for: "" }, [
              _createElementVNode("strong", { class: "text-lg" }, "Cidade"),
              _createTextVNode(" (obrigatório)")
            ], -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              placeholder: "ex: Fortaleza",
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((payload.value.address!.city) = $event))
            }, null, 512), [
              [_vModelText, payload.value.address!.city]
            ])
          ]),
          _createElementVNode("div", _hoisted_15, [
            _cache[23] || (_cache[23] = _createElementVNode("label", { for: "" }, [
              _createElementVNode("strong", { class: "text-lg" }, "Estado"),
              _createTextVNode(" (obrigatório)")
            ], -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              placeholder: "ex: Rj",
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((payload.value.address!.state) = $event))
            }, null, 512), [
              [_vModelText, payload.value.address!.state]
            ])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _cache[24] || (_cache[24] = _createElementVNode("label", { for: "" }, [
              _createElementVNode("strong", { class: "text-lg" }, "Complemento"),
              _createTextVNode(" (opcional)")
            ], -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              placeholder: "ex: Alto",
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((payload.value.address!.complement) = $event))
            }, null, 512), [
              [_vModelText, payload.value.address!.complement]
            ])
          ])
        ])
      ]),
      _createElementVNode("section", _hoisted_17, [
        _createElementVNode("section", _hoisted_18, [
          _cache[26] || (_cache[26] = _createElementVNode("div", { class: "font-inter" }, [
            _createElementVNode("label", { for: "" }, [
              _createElementVNode("strong", { class: "text-lg" }, "Motivo de Bloqueio"),
              _createTextVNode(" (obrigatório)")
            ]),
            _createElementVNode("p", { class: "text-6f font-inter text-sm" }, " Adicione o motivo pelo qual você está denunciando essa empresa ")
          ], -1)),
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, [
              _withDirectives(_createElementVNode("textarea", {
                type: "text",
                placeholder: "ex: Esta empresa não passou no compliance",
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((payload.value.complaint_reason) = $event))
              }, null, 512), [
                [_vModelText, payload.value.complaint_reason]
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("nav", null, [
        (!_unref(isLoading))
          ? (_openBlock(), _createElementBlock("input", {
              key: 0,
              type: "button",
              value: "Criar",
              onClick: createCustomer,
              disabled: 
            !payload.value.name ||
            !payload.value.document ||
            (payload.value.document?.length != 14 &&
              payload.value.document?.length != 18) ||
            !payload.value.email ||
            payload.value.phone_number?.length != 16 ||
            !payload.value.complaint_reason ||
            !isAddressValid.value
          
            }, null, 8, _hoisted_21))
          : (_openBlock(), _createBlock(SpinnerLoader, { key: 1 })),
        _createElementVNode("input", {
          type: "button",
          value: "Cancelar",
          onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.$router.push({ name: 'companies.query' })))
        })
      ])
    ])
  ]))
}
}

})