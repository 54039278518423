import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "show-company" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "grid grid-cols-12 gap-4" }
const _hoisted_4 = { class: "col-span-6 flex flex-col gap-2" }
const _hoisted_5 = { class: "flex flex-col gap-2" }
const _hoisted_6 = { class: "flex justify-between font-inter items-center" }
const _hoisted_7 = { class: "p-8" }
const _hoisted_8 = { class: "flex items-center font-inter justify-between mb-4" }
const _hoisted_9 = { class: "font-inter flex justify-between pt-8 submit-wrapper" }
const _hoisted_10 = { class: "p-8" }
const _hoisted_11 = { class: "flex items-center font-inter justify-between mb-4" }
const _hoisted_12 = { class: "font-inter flex justify-between pt-8 submit-wrapper" }

import MyModal from "@/components/modal/MyModal.vue";
import { ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ShowCompanyView',
  setup(__props) {

const isCreateBlockingReasonModalOpen = ref(false);
const isEditPartnersModalOpen = ref(false);

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_RouterLink, {
        to: { name: 'companies' },
        class: "flex items-center gap-2 mb-8 go-back"
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [
          _createElementVNode("div", { class: "bg-zinc-100 p-3 rounded-full go-back__icon-wrapper" }, [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              width: "24",
              height: "24",
              viewBox: "0 0 24 24",
              fill: "none"
            }, [
              _createElementVNode("path", {
                d: "M9.57 5.92969L3.5 11.9997L9.57 18.0697M20.5 11.9997H3.67",
                stroke: "#090909",
                "stroke-width": "1.5",
                "stroke-miterlimit": "10",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              })
            ])
          ], -1),
          _createElementVNode("h1", { class: "text-3xl font-bold font-nunito" }, "Nome da empresa", -1)
        ])),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[8] || (_cache[8] = _createStaticVNode("<div class=\"col-span-6 flex flex-col gap-4\"><section class=\"flex flex-col gap-4 grid grid-cols-12\"><div class=\"font-inter flex flex-col gap-2 col-span-6\"><label class=\"font-bold text-lg\" for=\"\">Razão social</label><input type=\"text\" placeholder=\"ex: Empresa Pay\"></div><div class=\"font-inter flex flex-col gap-2 col-span-6\"><label class=\"font-bold text-lg\" for=\"\">Nome Fantasia</label><input type=\"text\" placeholder=\"ex: Empresa Pay\"></div><div class=\"font-inter flex flex-col gap-2 col-span-6\"><label class=\"font-bold text-lg\" for=\"\">CPF/CNPJ</label><input type=\"text\" placeholder=\"ex: 000.000.000-00\"></div><div class=\"font-inter flex flex-col gap-2 col-span-6\"><label class=\"font-bold text-lg\" for=\"\">Email</label><input type=\"text\" placeholder=\"ex: email@gmail.com\"></div><div class=\"font-inter flex flex-col gap-2 col-span-6\"><label class=\"font-bold text-lg\" for=\"\">Total de aderentes</label><input type=\"text\" placeholder=\"ex: 10\"></div><div class=\"font-inter flex flex-col gap-2 col-span-6\"><label class=\"font-bold text-lg\" for=\"\">Total pendentes</label><input type=\"text\" placeholder=\"ex: 8\"></div><div class=\"font-inter flex flex-col gap-2 col-span-12\"><label class=\"font-bold text-lg\" for=\"\">Data de cadastro</label><input type=\"text\" placeholder=\"ex: 07/08/2021\"></div></section></div>", 1)),
      _createElementVNode("section", _hoisted_4, [
        _createElementVNode("section", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _cache[7] || (_cache[7] = _createElementVNode("h2", { class: "font-inter font-bold text-xl" }, "Motivo do bloqueio", -1)),
            _createElementVNode("button", {
              class: "border border-emerald-600 border-2 rounded-3xl px-4 py-2 font-bold text-emerald-600 flex items-center gap-2 add",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (isCreateBlockingReasonModalOpen.value = true))
            }, _cache[6] || (_cache[6] = [
              _createElementVNode("svg", {
                xmlns: "http://www.w3.org/2000/svg",
                width: "24",
                height: "24",
                viewBox: "0 0 24 24",
                fill: "none"
              }, [
                _createElementVNode("path", {
                  d: "M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z",
                  stroke: "#171717",
                  class: "stroke-emerald-600",
                  "stroke-width": "1.5",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round"
                }),
                _createElementVNode("path", {
                  d: "M8 12H16",
                  stroke: "#171717",
                  "stroke-width": "1.5",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  class: "stroke-emerald-600"
                }),
                _createElementVNode("path", {
                  d: "M12 16V8",
                  stroke: "#171717",
                  "stroke-width": "1.5",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  class: "stroke-emerald-600"
                })
              ], -1),
              _createTextVNode(" Adicionar ")
            ]))
          ])
        ])
      ])
    ]),
    _createVNode(MyModal, {
      isOpen: isCreateBlockingReasonModalOpen.value,
      toggleModal: (v) => (isCreateBlockingReasonModalOpen.value = v),
      width: '40rem',
      height: '33rem',
      id: 'create-blocking-reason-modal'
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _cache[10] || (_cache[10] = _createElementVNode("h2", { class: "text-2xl font-bold" }, "Adicione um motivo de bloqueio", -1)),
            _createElementVNode("button", {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (isCreateBlockingReasonModalOpen.value = false))
            }, _cache[9] || (_cache[9] = [
              _createElementVNode("svg", {
                xmlns: "http://www.w3.org/2000/svg",
                width: "24",
                height: "24",
                viewBox: "0 0 24 24",
                fill: "none"
              }, [
                _createElementVNode("path", {
                  d: "M18 6L6 18",
                  stroke: "#090909",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round"
                }),
                _createElementVNode("path", {
                  d: "M6 6L18 18",
                  stroke: "#090909",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round"
                })
              ], -1)
            ]))
          ]),
          _cache[12] || (_cache[12] = _createElementVNode("div", { class: "flex flex-col gap-4 py-4" }, [
            _createElementVNode("div", { class: "font-inter flex flex-col gap-2 col-span-6" }, [
              _createElementVNode("label", { class: "font-bold text-lg" }, "Motivo"),
              _createElementVNode("textarea", {
                type: "text",
                placeholder: "ex: "
              })
            ])
          ], -1)),
          _createElementVNode("div", _hoisted_9, [
            _cache[11] || (_cache[11] = _createElementVNode("button", { class: "bg-emerald-600 font-bold text-white px-12 py-3 rounded-3xl" }, " Enviar ", -1)),
            _createElementVNode("button", {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (isCreateBlockingReasonModalOpen.value = false))
            }, " Cancelar ")
          ])
        ])
      ]),
      _: 1
    }, 8, ["isOpen", "toggleModal"]),
    _createVNode(MyModal, {
      isOpen: isEditPartnersModalOpen.value,
      toggleModal: (v) => (isEditPartnersModalOpen.value = v),
      width: '36rem',
      height: '40rem',
      id: 'edit-partner-modal'
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _cache[14] || (_cache[14] = _createElementVNode("h2", { class: "text-2xl font-bold" }, "Adicione um sócio", -1)),
            _createElementVNode("button", {
              onClick: _cache[3] || (_cache[3] = ($event: any) => (isEditPartnersModalOpen.value = false))
            }, _cache[13] || (_cache[13] = [
              _createElementVNode("svg", {
                xmlns: "http://www.w3.org/2000/svg",
                width: "24",
                height: "24",
                viewBox: "0 0 24 24",
                fill: "none"
              }, [
                _createElementVNode("path", {
                  d: "M18 6L6 18",
                  stroke: "#090909",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round"
                }),
                _createElementVNode("path", {
                  d: "M6 6L18 18",
                  stroke: "#090909",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round"
                })
              ], -1)
            ]))
          ]),
          _cache[16] || (_cache[16] = _createElementVNode("div", { class: "flex flex-col gap-4 py-4" }, [
            _createElementVNode("div", { class: "font-inter flex flex-col gap-2 col-span-6" }, [
              _createElementVNode("label", { class: "font-bold text-lg" }, "Nome"),
              _createElementVNode("input", {
                type: "text",
                placeholder: "ex: João Silva"
              })
            ]),
            _createElementVNode("div", { class: "font-inter flex flex-col gap-2 col-span-6" }, [
              _createElementVNode("label", { class: "font-bold text-lg" }, "Documento"),
              _createElementVNode("input", {
                type: "text",
                placeholder: "ex: 000.000.000-00"
              })
            ]),
            _createElementVNode("div", { class: "font-inter flex flex-col gap-2 col-span-12" }, [
              _createElementVNode("label", { class: "font-bold text-lg" }, "Porcentagem"),
              _createElementVNode("input", {
                type: "number",
                placeholder: "ex: 15%"
              })
            ]),
            _createElementVNode("div", { class: "font-inter flex flex-col gap-2 col-span-12" }, [
              _createElementVNode("label", { class: "font-bold text-lg" }, "Data de nascimento"),
              _createElementVNode("input", {
                type: "text",
                placeholder: "ex: 07/08/2000"
              })
            ])
          ], -1)),
          _createElementVNode("div", _hoisted_12, [
            _cache[15] || (_cache[15] = _createElementVNode("button", { class: "bg-emerald-600 font-bold text-white px-12 py-3 rounded-3xl" }, " Enviar ", -1)),
            _createElementVNode("button", {
              onClick: _cache[4] || (_cache[4] = ($event: any) => (isEditPartnersModalOpen.value = false))
            }, "Cancelar")
          ])
        ])
      ]),
      _: 1
    }, 8, ["isOpen", "toggleModal"])
  ]))
}
}

})