import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, unref as _unref, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "create-user h-full relative pt-12 sm:pt-6" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "flex flex-col gap-4 grid grid-cols-12" }
const _hoisted_4 = { class: "font-inter flex flex-col gap-2 col-span-6 text-sm" }
const _hoisted_5 = { class: "font-inter flex flex-col gap-2 col-span-6" }
const _hoisted_6 = { class: "font-inter flex flex-col gap-2 col-span-6" }
const _hoisted_7 = { class: "font-inter flex flex-col gap-2 col-span-6" }
const _hoisted_8 = { class: "col-span-12 flex flex-col gap-4" }
const _hoisted_9 = { class: "flex flex-col gap-4 grid grid-cols-12" }
const _hoisted_10 = { class: "col-span-12 pt-4 flex items-center gap-4" }
const _hoisted_11 = { class: "font-inter flex flex-col gap-2 sm:col-span-6 col-span-12 text-sm" }
const _hoisted_12 = { class: "font-inter flex flex-col gap-2 sm:col-span-6 col-span-12 text-sm" }
const _hoisted_13 = { class: "font-inter flex flex-col gap-2 sm:col-span-6 col-span-12 text-sm" }
const _hoisted_14 = { class: "font-inter flex flex-col gap-2 sm:col-span-6 col-span-12 text-sm" }
const _hoisted_15 = { class: "font-inter flex flex-col gap-2 sm:col-span-6 col-span-12 text-sm" }
const _hoisted_16 = { class: "font-inter flex flex-col gap-2 sm:col-span-6 col-span-12 text-sm" }
const _hoisted_17 = { class: "font-inter flex flex-col gap-2 sm:col-span-6 col-span-12 text-sm" }
const _hoisted_18 = ["disabled"]

import axiosClient from "@/api/axios";
import { vMaska } from "maska/vue";
import { ref, computed } from "vue";
import SpinnerLoader from "@/components/loader/SpinnerLoader.vue";
import { toast } from "vue3-toastify";
import SpinnerLoaderButton from "@/components/loader/SpinnerLoaderButton.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateUserView',
  setup(__props) {

const payload = ref<any>({ address: {} });
const isCreateUserRequestLoading = ref(false);
const isAddressRequestLoading = ref(false);
const isAddressValid = computed(() => {
  if (payload.value.address?.postal_code?.length !== 9) {
    return false;
  }

  return (
    payload.value.address.street &&
    payload.value.address.number &&
    payload.value.address.neighborhood &&
    payload.value.address.city &&
    payload.value.address.state
  );
});

function createUser() {
  isCreateUserRequestLoading.value = true;

  axiosClient
    .post("/sessions/register", payload.value)
    .then((res) => {
      payload.value = { address: {} };
      toast.success("Usuário cadastrado com sucesso");
    })
    .finally(() => (isCreateUserRequestLoading.value = false));
}

function fillAddress() {
  if (payload.value.document.length === 18) {
    isAddressRequestLoading.value = true;
    axiosClient
      .get(
        `https://publica.cnpj.ws/cnpj/${payload.value.document.replace(
          /\D/g,
          ""
        )}`
      )
      .then((res: any) => {
        payload.value.address.postal_code = res.data.estabelecimento.cep;
        payload.value.address.street = res.data.estabelecimento.logradouro;
        payload.value.address.number = res.data.estabelecimento.numero;
        payload.value.address.neighborhood = res.data.estabelecimento.bairro;
        payload.value.address.city = res.data.estabelecimento.cidade.nome;
        payload.value.address.state = res.data.estabelecimento.estado.sigla;
        payload.value.address.complement =
          res.data.estabelecimento.estado.complemento;
        payload.value.name = res.data.razao_social;
      })
      .catch(() => {
        toast.error(
          "Erro ao buscar endereço, verifique se o CNPJ está correto"
        );
        payload.value.document = "";
      })
      .finally(() => (isAddressRequestLoading.value = false));
  }
}

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_RouterLink, {
        to: { name: 'companies.query' },
        class: "flex items-center gap-2 mb-8 go-back"
      }, {
        default: _withCtx(() => _cache[12] || (_cache[12] = [
          _createElementVNode("div", { class: "bg-zinc-100 p-3 rounded-full go-back__icon-wrapper" }, [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              width: "24",
              height: "24",
              viewBox: "0 0 24 24",
              fill: "none"
            }, [
              _createElementVNode("path", {
                d: "M9.57 5.92969L3.5 11.9997L9.57 18.0697M20.5 11.9997H3.67",
                stroke: "#090909",
                "stroke-width": "1.5",
                "stroke-miterlimit": "10",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              })
            ])
          ], -1),
          _createElementVNode("h1", { class: "text-3xl font-bold font-nunito" }, "Cadastrar usuário", -1)
        ])),
        _: 1
      })
    ]),
    _createElementVNode("section", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _cache[13] || (_cache[13] = _createElementVNode("label", { for: "" }, [
          _createElementVNode("strong", { class: "text-lg" }, "CNPJ"),
          _createTextVNode(" (obrigatório)")
        ], -1)),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          placeholder: "ex: 000.000.000-00",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((payload.value.document) = $event))
        }, null, 512), [
          [_unref(vMaska), 
            (payload.value.document ?? '').length <= 14
              ? '###.###.###-###'
              : '##.###.###/####-##'
          ],
          [_vModelText, payload.value.document]
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _cache[14] || (_cache[14] = _createElementVNode("label", { for: "" }, [
          _createElementVNode("strong", { class: "text-lg" }, "Razão Social"),
          _createTextVNode(" (obrigatório)")
        ], -1)),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((payload.value.name) = $event)),
          readonly: ""
        }, null, 512), [
          [_vModelText, payload.value.name]
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _cache[15] || (_cache[15] = _createElementVNode("label", { for: "" }, [
          _createElementVNode("strong", { class: "text-lg" }, "Email"),
          _createTextVNode(" (obrigatório)")
        ], -1)),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          placeholder: "ex: email@gmail.com",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((payload.value.email) = $event))
        }, null, 512), [
          [_vModelText, payload.value.email]
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _cache[16] || (_cache[16] = _createElementVNode("label", { for: "" }, [
          _createElementVNode("strong", { class: "text-lg" }, "Senha"),
          _createTextVNode(" (obrigatório)")
        ], -1)),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          placeholder: "ex: 123456",
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((payload.value.password) = $event))
        }, null, 512), [
          [_vModelText, payload.value.password]
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("section", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _cache[17] || (_cache[17] = _createElementVNode("div", null, [
              _createElementVNode("h2", { class: "text-xl font-inter" }, [
                _createElementVNode("strong", null, "Endereço do cliente")
              ]),
              _createElementVNode("p", { class: "text-6f font-inter text-sm" }, " Adicione o máximo de informações do endereço do cliente. ")
            ], -1)),
            (
                payload.value.document?.length === 18 &&
                !isAddressRequestLoading.value &&
                !payload.value.address?.postal_code
              )
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "border border-emerald-600 border-2 rounded-3xl px-4 py-2 font-bold text-emerald-600 add",
                  onClick: fillAddress
                }, " Preencher endereço "))
              : _createCommentVNode("", true),
            (isAddressRequestLoading.value)
              ? (_openBlock(), _createBlock(SpinnerLoader, { key: 1 }))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _cache[18] || (_cache[18] = _createElementVNode("label", { for: "" }, [
              _createElementVNode("strong", { class: "text-lg" }, "CEP"),
              _createTextVNode(" (obrigatório)")
            ], -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((payload.value.address!.postal_code) = $event)),
              readonly: ""
            }, null, 512), [
              [_unref(vMaska), '#####-###'],
              [_vModelText, payload.value.address!.postal_code]
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _cache[19] || (_cache[19] = _createElementVNode("label", { for: "" }, [
              _createElementVNode("strong", { class: "text-lg" }, "Rua"),
              _createTextVNode(" (obrigatório)")
            ], -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((payload.value.address!.street) = $event)),
              readonly: ""
            }, null, 512), [
              [_vModelText, payload.value.address!.street]
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _cache[20] || (_cache[20] = _createElementVNode("label", { for: "" }, [
              _createElementVNode("strong", { class: "text-lg" }, "Número"),
              _createTextVNode(" (obrigatório)")
            ], -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((payload.value.address!.number) = $event)),
              readonly: ""
            }, null, 512), [
              [_vModelText, payload.value.address!.number]
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _cache[21] || (_cache[21] = _createElementVNode("label", { for: "" }, [
              _createElementVNode("strong", { class: "text-lg" }, "Bairro"),
              _createTextVNode(" (obrigatório)")
            ], -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((payload.value.address!.neighborhood) = $event)),
              readonly: ""
            }, null, 512), [
              [_vModelText, payload.value.address!.neighborhood]
            ])
          ]),
          _createElementVNode("div", _hoisted_15, [
            _cache[22] || (_cache[22] = _createElementVNode("label", { for: "" }, [
              _createElementVNode("strong", { class: "text-lg" }, "Cidade"),
              _createTextVNode(" (obrigatório)")
            ], -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((payload.value.address!.city) = $event)),
              readonly: ""
            }, null, 512), [
              [_vModelText, payload.value.address!.city]
            ])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _cache[23] || (_cache[23] = _createElementVNode("label", { for: "" }, [
              _createElementVNode("strong", { class: "text-lg" }, "Estado"),
              _createTextVNode(" (obrigatório)")
            ], -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((payload.value.address!.state) = $event)),
              readonly: ""
            }, null, 512), [
              [_vModelText, payload.value.address!.state]
            ])
          ]),
          _createElementVNode("div", _hoisted_17, [
            _cache[24] || (_cache[24] = _createElementVNode("label", { for: "" }, [
              _createElementVNode("strong", { class: "text-lg" }, "Complemento"),
              _createTextVNode(" (opcional)")
            ], -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((payload.value.address!.complement) = $event)),
              readonly: ""
            }, null, 512), [
              [_vModelText, payload.value.address!.complement]
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("nav", null, [
      (!isCreateUserRequestLoading.value)
        ? (_openBlock(), _createElementBlock("input", {
            key: 0,
            type: "button",
            value: "Criar",
            onClick: createUser,
            disabled: 
          !payload.value.name ||
          !payload.value.document ||
          (payload.value.document?.length != 14 && payload.value.document?.length != 18) ||
          !payload.value.email ||
          !payload.value.password ||
          !isAddressValid.value
        
          }, null, 8, _hoisted_18))
        : (_openBlock(), _createBlock(SpinnerLoaderButton, {
            key: 1,
            height: 60,
            maxWidth: 464
          })),
      _createElementVNode("input", {
        type: "button",
        value: "Cancelar",
        onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.$router.push('/admin/home')))
      })
    ])
  ]))
}
}

})