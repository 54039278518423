<template>
  <div class="font-inter p-6">
    <svg xmlns="http://www.w3.org/2000/svg" width="104" height="104" viewBox="0 0 24 24" fill="none" class="mx-auto">
      <path d="M18.75 8V10.1C18.31 10.04 17.81 10.01 17.25 10V8C17.25 4.85 16.36 2.75 12 2.75C7.64 2.75 6.75 4.85 6.75 8V10C6.19 10.01 5.69 10.04 5.25 10.1V8C5.25 5.1 5.95 1.25 12 1.25C18.05 1.25 18.75 5.1 18.75 8Z" fill="#b3b2b2"/>
      <path d="M18.75 10.1C18.31 10.04 17.81 10.01 17.25 10H6.75C6.19 10.01 5.69 10.04 5.25 10.1C2.7 10.41 2 11.66 2 15V17C2 21 3 22 7 22H17C21 22 22 21 22 17V15C22 11.66 21.3 10.41 18.75 10.1ZM8.71 16.71C8.52 16.89 8.26 17 8 17C7.87 17 7.74 16.97 7.62 16.92C7.49 16.87 7.39 16.8 7.29 16.71C7.11 16.52 7 16.26 7 16C7 15.87 7.03 15.74 7.08 15.62C7.13 15.5 7.2 15.39 7.29 15.29C7.39 15.2 7.49 15.13 7.62 15.08C7.99 14.92 8.43 15.01 8.71 15.29C8.8 15.39 8.87 15.5 8.92 15.62C8.97 15.74 9 15.87 9 16C9 16.26 8.89 16.52 8.71 16.71ZM12.92 16.38C12.87 16.5 12.8 16.61 12.71 16.71C12.52 16.89 12.26 17 12 17C11.73 17 11.48 16.89 11.29 16.71C11.2 16.61 11.13 16.5 11.08 16.38C11.03 16.26 11 16.13 11 16C11 15.73 11.11 15.48 11.29 15.29C11.66 14.92 12.33 14.92 12.71 15.29C12.89 15.48 13 15.73 13 16C13 16.13 12.97 16.26 12.92 16.38ZM16.71 16.71C16.52 16.89 16.26 17 16 17C15.74 17 15.48 16.89 15.29 16.71C15.11 16.52 15 16.27 15 16C15 15.73 15.11 15.48 15.29 15.29C15.67 14.92 16.34 14.92 16.71 15.29C16.75 15.34 16.79 15.39 16.83 15.45C16.87 15.5 16.9 15.56 16.92 15.62C16.95 15.68 16.97 15.74 16.98 15.8C16.99 15.87 17 15.94 17 16C17 16.26 16.89 16.52 16.71 16.71Z" fill="#b3b2b2"/>
    </svg>
    <h2 class="text-3xl font-bold text-center">Autenticação 2Fatores</h2>
    <p class="text-center">Abra o app Authenticator para ver o código</p>
    <input v-model="code" type="password" placeholder="Digite o código" class="my-6" />
    <button v-if="!isLoading" :disabled="code.length < 6" @click="validateCode">Verificar</button>
    <SpinnerLoaderButton v-else :height="60" :maxWidth="464" />
  </div>
</template>

<script lang="ts" setup>
import axiosClient from '@/api/axios'
import { ref } from 'vue'
import SpinnerLoaderButton from '@/components/loader/SpinnerLoaderButton.vue'
import { toast } from 'vue3-toastify';

const emit = defineEmits(['onCodeValidated'])
const isLoading = ref(false)
const code = ref('')

function validateCode() {
  isLoading.value = true

  axiosClient.post('/accounts/2af/validate', { code: code.value.replaceAll(' ', '') })
    .then((res) => {
      const domain = '.' + process.env?.VUE_APP_DOMAIN;
      document.cookie = `authenticated_user=${encodeURIComponent(JSON.stringify(res.data.user))}; path=/; domain=${domain}; secure; SameSite=Lax`;
      document.cookie = `auth_token=${encodeURIComponent(res.data.access_token)}; path=/; domain=${domain}; secure; SameSite=Lax`;
      localStorage.setItem('hasValidated2AF', 'true')
      toast.success('Código validado')
      emit('onCodeValidated')
    })
    .catch(() => toast.error('Código inválido'))
    .finally(() => isLoading.value = false)
}
</script>

<style lang="scss" scoped>
input{
  width: 100%;
  height: 55px;
  padding: 0 18px;
  border-radius: 16px;
  border: none;
  background: #F5F5F5;
  box-sizing: border-box;
  color: #090909;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  &::placeholder{
    color: #090909;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
  }
  &:focus{
    outline: none;
    color: $primary-color;
    padding: 0 16px !important;
    &::placeholder{
      color: transparent;
    }
    & ~ button{
      svg{
        stroke: $primary-color !important;
      }
    }
  }
  &:read-only{
    cursor: default;
    color: $primary-color !important;
  }
}
button {
  width: 100%;
  height: 60px;
  border: none;
  border-radius: 30px;
  background: $primary-color;
  color: #FFF;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 18px;
  margin: auto 0 0 0;
  transition: background .2s;
  cursor: pointer;
  &:hover{
    background: $primary-color-hover;
  }
  &:disabled{
    background: #F1F1F1 !important;
    color: #6F6F6F !important;
    cursor: not-allowed !important;
  }
}
</style>