import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, unref as _unref, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "create-company pt-12 sm:pt-6" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "grid grid-cols-12 gap-4" }
const _hoisted_4 = { class: "col-span-12 sm:col-span-6 flex flex-col gap-6" }
const _hoisted_5 = { class: "flex flex-col gap-4 grid grid-cols-12" }
const _hoisted_6 = { class: "font-inter flex flex-col gap-2 col-span-12" }
const _hoisted_7 = { class: "col-span-12 sm:col-span-6 flex flex-col gap-2" }
const _hoisted_8 = { class: "flex flex-col gap-2" }
const _hoisted_9 = { class: "flex flex-col gap-4 py-4" }
const _hoisted_10 = { class: "font-inter flex flex-col gap-2 col-span-6" }
const _hoisted_11 = ["disabled"]

import { ref } from "vue";
// import { vMaska } from "maska/vue";
import { isLoading } from "@/global/states/GlobalState";
import SpinnerLoader from "@/components/loader/SpinnerLoader.vue";
import axiosClient from "@/api/axios";
import { useRoute } from "vue-router";
import { toast } from "vue3-toastify";


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateCompanyView',
  setup(__props) {

const route = useRoute();
const payload = ref<any>({ document: route.query.document });
const isEditPartnersModalOpen = ref(false);
// const isFetchCnpjRequestLoading = ref(false);

function createCompany() {
  isLoading.value = true;

  axiosClient
    .post("/companies/insert", payload.value)
    .then(() => {
      payload.value = {};
      toast.success("Empresa cadastrada com sucesso");
    })
    .finally(() => (isLoading.value = false));
}

// function fetchCnpjData() {
//   payload.value.name = "";
//   payload.value.name_fantasy = "";

//   if (payload.value.document.length === 18) {
//     isFetchCnpjRequestLoading.value = true;

//     axiosClient
//       .get(
//         `https://publica.cnpj.ws/cnpj/${payload.value.document.replace(
//           /\D/g,
//           ""
//         )}`
//       )
//       .then((res: any) => {
//         payload.value.name = res.data.razao_social;
//         payload.value.name_fantasy = res.data.estabelecimento.nome_fantasia;
//       })
//       .catch(() => {
//         toast.error(
//           "Erro ao buscar razão social e nome fantasia, verifique se o CNPJ está correto"
//         );
//         payload.value.document = "";
//       })
//       .finally(() => (isFetchCnpjRequestLoading.value = false));
//   }
// }

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_RouterLink, {
        to: { name: 'companies.query' },
        class: "flex items-center gap-2 mb-8 go-back"
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createElementVNode("div", { class: "bg-zinc-100 p-3 rounded-full go-back__icon-wrapper" }, [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              width: "24",
              height: "24",
              viewBox: "0 0 24 24",
              fill: "none"
            }, [
              _createElementVNode("path", {
                d: "M9.57 5.92969L3.5 11.9997L9.57 18.0697M20.5 11.9997H3.67",
                stroke: "#090909",
                "stroke-width": "1.5",
                "stroke-miterlimit": "10",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              })
            ])
          ], -1),
          _createElementVNode("h1", { class: "text-3xl font-bold font-nunito" }, "Cadastrar empresa", -1)
        ])),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("section", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _cache[5] || (_cache[5] = _createElementVNode("label", { for: "" }, [
              _createElementVNode("strong", { class: "text-lg" }, "CPF/CNPJ"),
              _createTextVNode(" (obrigatório)")
            ], -1)),
            _withDirectives(_createElementVNode("input", {
              type: "tel",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((payload.value.document) = $event)),
              onBlur: _cache[1] || (_cache[1] = ($event: any) => (payload.value.document.length !== 64 ? _unref(toast).error('Por favor, criptografe o número do documento utilizando a criptografia sha256') : ''))
            }, null, 544), [
              [_vModelText, payload.value.document]
            ])
          ])
        ])
      ]),
      _createElementVNode("section", _hoisted_7, [
        _createElementVNode("section", _hoisted_8, [
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "font-inter" }, [
            _createElementVNode("label", { for: "" }, [
              _createElementVNode("strong", { class: "text-lg" }, "Motivo de Bloqueio"),
              _createTextVNode(" (obrigatório)")
            ]),
            _createElementVNode("p", { class: "text-6f font-inter text-sm" }, " Adicione o motivo pelo qual você está denunciando essa empresa ")
          ], -1)),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _withDirectives(_createElementVNode("textarea", {
                type: "text",
                placeholder: "ex: Esta empresa não passou no compliance",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((payload.value.reason) = $event))
              }, null, 512), [
                [_vModelText, payload.value.reason]
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("nav", null, [
        (!_unref(isLoading))
          ? (_openBlock(), _createElementBlock("input", {
              key: 0,
              type: "button",
              value: "Criar",
              onClick: createCompany,
              disabled: 
            // !payload.name ||
            //!payload.name_fantasy ||
            !payload.value.document ||
            (payload.value.document?.length !== 64) ||
            // !payload.email ||
            // payload.phone?.length != 16 ||
            !payload.value.reason
          
            }, null, 8, _hoisted_11))
          : (_openBlock(), _createBlock(SpinnerLoader, { key: 1 })),
        _createElementVNode("input", {
          type: "button",
          value: "Cancelar",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push({ name: 'companies.query' })))
        })
      ])
    ])
  ]))
}
}

})