import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "credentials pt-12 sm:pt-6" }
const _hoisted_2 = { class: "grid grid-cols-12 gap-4 mt-6" }
const _hoisted_3 = { class: "col-span-12 sm:col-span-6" }
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "col-span-12 sm:col-span-6" }
const _hoisted_6 = ["value"]
const _hoisted_7 = { class: "mt-4" }

import { ref } from 'vue';
import axiosClient from '@/api/axios'
import SpinnerLoaderButton from "@/components/loader/SpinnerLoaderButton.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CredentialsView',
  setup(__props) {

const credentials = ref({ clientId: '', clientSecret: '' })
const isGeneratingCredentials = ref(false)

function generateCredentials() {
  isGeneratingCredentials.value = true

  axiosClient.post('/accounts/developer/credentials')
    .then((res) => {
      credentials.value = res.data
    })
    .finally(() => isGeneratingCredentials.value = false)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createStaticVNode("<h1 class=\"text-3xl font-bold font-nunito\" data-v-58fc1218>Credenciais</h1><p class=\"text-gray-500 text-sm font-inter\" data-v-58fc1218>Gerencie as credenciais de autorização da API</p><a href=\"@/assets/documentation.json\" download=\"documentation.json\" class=\"inline-flex items-center gap-2 font-inter px-4 py-2 border rounded-xl my-2 mt-3\" data-v-58fc1218><svg xmlns=\"http://www.w3.org/2000/svg\" width=\"25\" height=\"24\" viewBox=\"0 0 25 24\" fill=\"none\" data-v-58fc1218><path d=\"M9.5 11V17L11.5 15\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-58fc1218></path><path d=\"M9.5 17L7.5 15\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-58fc1218></path><path d=\"M22.5 10V15C22.5 20 20.5 22 15.5 22H9.5C4.5 22 2.5 20 2.5 15V9C2.5 4 4.5 2 9.5 2H14.5\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-58fc1218></path><path d=\"M22.5 10H18.5C15.5 10 14.5 9 14.5 6V2L22.5 10Z\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-58fc1218></path></svg> Baixar documentação </a>", 3)),
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("label", { for: "client-id" }, [
          _createElementVNode("strong", null, "Client id")
        ], -1)),
        _createElementVNode("input", {
          autocomplete: "off",
          value: credentials.value.clientId,
          type: "text",
          id: "client-id"
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _cache[1] || (_cache[1] = _createElementVNode("label", { for: "client-secret" }, [
          _createElementVNode("strong", null, "Client secret")
        ], -1)),
        _createElementVNode("input", {
          autocomplete: "off",
          value: credentials.value.clientSecret,
          type: "text",
          id: "client-secret"
        }, null, 8, _hoisted_6)
      ])
    ]),
    (!credentials.value.clientId && !isGeneratingCredentials.value)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: generateCredentials,
          id: "genarate-credentials"
        }, "Gerar credenciais"))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_7, [
      (isGeneratingCredentials.value)
        ? (_openBlock(), _createBlock(SpinnerLoaderButton, {
            key: 0,
            height: 60,
            maxWidth: 200
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})