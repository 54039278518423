import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import ManageCompanies from '@/views/companies/ManageCompanies.vue'
import MainLayout from '@/layouts/MainLayout.vue'
import ShowCompanyView from '@/views/companies/ShowCompanyView.vue'
import CreateCompanyView from '@/views/companies/CreateCompanyView.vue'
import CreateUserView from '@/views/users/CreateUserView.vue'
import QueryCompanyView from '@/views/companies/QueryCompanyView.vue'
import CreateCustomerView from '@/views/customers/CreateCustomerView.vue'
import QueryCustomerView from '@/views/customers/QueryCustomerView.vue'
import ProfileView from '@/views/profile/ProfileView.vue'
import CredentialsView from '@/views/credentials/CredentialsView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/admin/companies/query'
  },
  {
    path: '/',
    component: MainLayout,
    children: [
      // {
      //   path: '/companies',
      //   name: 'companies',
      //   component: ManageCompanies
      // },
      {
        path: '/admin/profile',
        name: 'profile',
        component: ProfileView
      },
      {
        path: '/admin/companies/query',
        name: 'companies.query',
        component: QueryCompanyView
      },
      {
        path: '/admin/companies/create',
        name: 'companies.create',
        component: CreateCompanyView
      },
      {
        path: '/admin/companies/1',
        name: 'companies.show',
        component: ShowCompanyView
      },

      {
        path: '/admin/users/create',
        name: 'users.create',
        component: CreateUserView
      },

      {
        path: '/admin/customers/query',
        name: 'customers.query',
        component: QueryCustomerView
      },
      {
        path: '/admin/customers/create',
        name: 'customers.create',
        component: CreateCustomerView
      },

      {
        path: '/admin/credentials',
        name: 'credentials',
        component: CredentialsView
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
